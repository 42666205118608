/** @format */

.survey-body {
  padding: 48px;
  margin: -20px -24px -8px;
  .survey-step {
    text-align: center;
    color: $dark-font;
    max-width: 600px;
    margin: 0 auto;
    &-step {
      font-size: 1.6rem;
      font-weight: 500;
      color: #9c9d9d;
      margin-bottom: 64px;
    }
    &-img {
      display: block;
      width: auto;
      margin: auto;
      max-width: 100%;
      object-fit: contain;
    }
    &-title {
      font-size: 1.8rem;
      font-weight: bold;
      margin: 24px 0;
      line-height: 1.2;
      @include media(xl) {
        font-size: 3rem;
        margin: 12px 0;
      }
    }
    &-content {
      font-size: 1.4rem;
      margin-top: 28px;
      @include media(xl) {
        font-size: 1.8rem;
      }
    }
  }
  .survey-step-btn {
    height: 54px;
    margin-top: 64px;
    font-size: 1.4rem;
    border-width: 0;
    padding: 0 24px;
    background: #fea500;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    @include media(xl) {
      font-size: 1.6rem;
      margin-top: 48px;
    }
    label {
      text-transform: none;
      /* background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none"><g clip-path="url(%23clip0_994_942)"><path d="M12.5 4L11.09 5.41L16.67 11H4.5V13H16.67L11.09 18.59L12.5 20L20.5 12L12.5 4Z" fill="black"/></g><defs><clipPath id="clip0_994_942"><rect width="24" height="24" fill="white" transform="translate(0.5)"/></clipPath></defs></svg>')
        left center no-repeat;
      padding-left: 32px; */
    }
  }
  .survey-step1 {
  }
  .survey-step2 {
    .survey-step-content {
      margin-bottom: 30px;
    }
  }
  .survey-step3 {
    .survey-step-title {
      margin-bottom: 60px;
    }
    .survey-step-btn {
      margin-top: 64px;
      label {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><g clip-path="url(%23clip0_1011_3157)"><path d="M9.00016 16.17L4.83016 12L3.41016 13.41L9.00016 19L21.0002 7.00003L19.5902 5.59003L9.00016 16.17Z" fill="black"/></g><defs><clipPath id="clip0_1011_3157"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>');
      }
    }
  }
  .survey-step4 {
    .survey-step-btn {
      padding-left: 0;
      label {
        background-image: none;
      }
    }
  }
}
