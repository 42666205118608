/** @format */

.tickets-widget {
  .tickets-container {
    background-color: $bgWidget;
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
    transition: 0.3s ease all;
    overflow: hidden;
  }
  .tickets-heading {
    font-size: 3rem;
  }
  &--list-empty {
    font-weight: bold;
  }
  .tickets-table {
    margin-top: 0;
    /* overflow-y: scroll; */
    /* @include media(md) {
      overflow: hidden;
    } */
    li {
      grid-template-columns: 1fr 84px;
      border: 0;
      border-bottom: 1px solid #d6d6d6;
      &:nth-child(1) {
        border-bottom: 0;
      }
      .attribute {
        transition: 0.3s ease all;
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(5) {
          display: none;
        }
        .ticket-link {
          color: black !important;
          text-decoration: underline !important;
          display: block;
          white-space: normal;
        }
      }
    }
    .ticket {
    }
  }
  .status-badge {
    padding: 5px 0;
    &--in_progress {
      background-color: #fea500;
      color: black;
    }
    &--on_hold {
      background-color: #fea500;
      color: black;
    }
    &--closed {
      background-color: $gray-dark;
    }
    &--submitted {
      background-color: #f4cf9b;
      color: black;
    }
    &--canceled {
      background-color: transparent;
      border: 1px solid $gray-dark;
      color: $gray-dark;
    }
  }

  .tickets-links {
    display: flex;
    gap: 2.4rem;
    margin-top: auto;
  }
  .tickets-link-all {
    margin-top: auto;
    font-weight: bold;
    background-image: url($arrow-right);
    background-repeat: no-repeat;
    background-position: left -1px;
    padding-left: 3rem;
    svg {
      display: none;
    }
    span {
      color: black;
    }
  }
  &[data-height='2'] {
    .tickets-container {
      gap: 2.2rem;
    }
    .tickets-table li:nth-child(n + 3) {
      display: none;
    }
    .tickets-table li .attribute {
      padding: 1rem 0;
    }
    .tickets-links {
      gap: 1.2rem;
    }
  }
  &[data-height='3'] {
    .tickets-container {
      gap: 2.2rem;
    }
    .tickets-table li:nth-child(n + 4) {
      display: none;
    }
    .tickets-table li .attribute {
      padding: 1rem 0;
    }
  }
  &[data-height='4'] {
    .tickets-table li .attribute {
      padding: 1.2rem 0;
    }
    .tickets-table li:nth-child(n + 5) {
      display: none;
    }
  }
  &[data-height='5'] {
    .tickets-table li:nth-child(n + 6) {
      display: none;
    }
  }
  &[data-width='1'] {
    .tickets-links {
      flex-direction: column;
    }
  }
  &[data-width='4'],
  &[data-width='5'],
  &[data-width='6'] {
    .tickets-table li {
      grid-template-columns: 2fr 1fr 84px;
      .attribute:nth-child(3) {
        display: block;
      }
    }
  }
  &[data-width='7'],
  &[data-width='8'],
  &[data-width='9'] {
    .tickets-table li {
      grid-template-columns: 1.5fr 2fr 110px 84px;
      .attribute:nth-child(2),
      .attribute:nth-child(3) {
        display: block;
      }
    }
  }
}
