/** @format */

$aspectRatio: #{'9 / 5'};
$gridArea1: #{'1 / 1 / 3 / 2'};
$gridArea2: #{'1 / 2 / 2 / 3'};
$gridArea3: #{'2 / 2 / 3 / 3'};

.video-widget {
  height: 100%;
  background-color: $bgWidget;
  padding: 2.4rem 2.4rem 3.2rem 2.4rem;
  border-radius: 1.6rem;
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: 3.2rem;
  overflow: hidden;
  transition: 0.3s ease all;
  &--title {
    h2 {
      font-size: 3rem;
    }
  }
  &--link {
    margin-top: auto;
    font-weight: bold;
    background-image: url($arrow-right);
    background-repeat: no-repeat;
    background-position: left -1px;
    padding-left: 3rem;
  }
  &--list-wrap {
  }
  &--list {
    font-weight: bold;
    font-size: 1.8rem;
    display: flex;
    gap: 5.4rem 2.4rem;
  }
  &--list-item {
    flex: auto;
  }
  &--list-item-title {
    margin-top: 1.4rem;
    @include textrow(1);
  }
  &--list-item-info {
    font-size: 1.3rem;
    display: flex;
    gap: 0.8rem;
    font-weight: 300;
    margin-top: 1rem;
  }
  &--list-item-video {
    display: block;
    object-fit: cover;
    border-radius: 0.8rem;
    transition: 0.3s ease all;
    position: relative;
    border-radius: 0.8rem;
    overflow: hidden;
    background-color: #eee;
    height: auto;
    aspect-ratio: $aspectRatio;
  }
  &--list-item-time {
    &:after {
      content: '•';
      display: inline-block;
      margin-left: 0.8rem;
    }
  }
  &--list-item-date {
    &:after {
      content: '•';
      display: inline-block;
      margin-left: 0.8rem;
    }
  }
  &--list-item-category {
  }
  &--list-empty {
    font-weight: bold;
  }

  &[data-width='3'],
  &[data-width='4'],
  &[data-width='5'] {
    .video-widget--list-item-title {
    }
    .video-widget--list-item:nth-child(n + 2) {
      display: none;
    }
  }
  &[data-width='6'] {
    .video-widget--list {
    }
    .video-widget--list-item {
      flex: 1;
    }
    .video-widget--list-item-title {
    }
    &[data-height='2'] {
      .video-widget--list-item-image {
        display: none;
      }
      .video-widget--list-item:nth-child(n + 3) {
        display: none;
      }
    }
    &[data-height='3'] {
      .video-widget--list-item:nth-child(n + 3) {
        display: none;
      }
      .video-widget--list-item-video {
        display: none;
      }
    }
    &[data-height='4'] {
      .video-widget--list-item:nth-child(n + 3) {
        display: none;
      }
      .video-widget--list-item-video {
      }
    }
    &[data-height='5'] {
      .video-widget--list-item:nth-child(n + 3) {
        display: none;
      }
      .video-widget--list-item-video {
      }
    }
    &[data-height='6'],
    &[data-height='7'],
    &[data-height='8'] {
      .video-widget--list-item:nth-child(n + 3) {
        display: none;
      }
      .video-widget--list-item-video {
      }
    }
  }

  /* Grid videos */
  &[data-width='4'] {
    &[data-height='4'] {
      .video-widget--list-item-video {
        aspect-ratio: 11/5;
      }
    }
    &[data-height='5'] {
      .video-widget--list-item-video {
        aspect-ratio: 11/5;
      }
    }
  }
  &[data-width='6'] {
    &[data-height='4'] {
      .video-widget--list-item {
        flex: 50% 0 0;
      }
    }
  }
  &[data-width='8'] {
    &[data-height='4'] {
      .video-widget--list-item {
        flex: 25% 0 0;
      }
      .video-widget--list-item-video {
        aspect-ratio: 13/7;
      }
    }
    &[data-height='6'] {
      .video-widget--list-item-video {
        aspect-ratio: 11/5;
      }
    }
  }
  &[data-width='9'][data-height='7'] {
    .video-widget--list-item-video {
      aspect-ratio: 10/5;
    }
  }
  &[data-width='7'][data-height='7'],
  &[data-width='7'][data-height='8'],
  &[data-width='8'][data-height='6'],
  &[data-width='8'][data-height='7'],
  &[data-width='8'][data-height='8'],
  &[data-width='9'][data-height='7'],
  &[data-width='9'][data-height='8'] {
    .video-widget--list {
      display: grid;
      grid-template-columns: 72% 1fr;
      grid-template-rows: repeat(2, 1fr);
    }
    .video-widget--list-item {
      &:nth-child(1) {
        grid-area: $gridArea1;
      }
      &:nth-child(2) {
        grid-area: $gridArea2;
      }
      &:nth-child(3) {
        grid-area: $gridArea3;
      }
    }
    .video-widget--list-item-video {
      width: 100%;
    }
    .video-widget--list-item:nth-child(n + 2) .video-widget--list-item-video {
    }
  }

  /* Responsive */
  /* < 1920 */
  @include media(XL) {
    &[data-width='5'][data-height='5'] {
      gap: 2.2rem;
    }
    &[data-width='7'] {
      .video-widget--list-item:nth-child(n + 3) {
        display: none;
      }
      &[data-height='5'],
      &[data-height='6'],
      &[data-height='7'],
      &[data-height='8'] {
        .video-widget--list {
          display: flex;
        }
      }
    }
    &[data-width='8'] {
      .video-widget--list-item-info {
        font-size: 1.2rem;
      }
      &[data-height='6'],
      &[data-height='7'],
      &[data-height='8'] {
        .video-widget--list-item-video {
          aspect-ratio: $aspectRatio;
        }
      }
    }
    &[data-width='9'] {
      &[data-height='4'],
      &[data-height='6'] {
        gap: 2.2rem;
      }
      &[data-height='6'],
      &[data-height='7'] {
        .video-widget--list {
          display: grid;
          grid-template-columns: 72% 1fr;
          grid-template-rows: repeat(2, 1fr);
        }
        .video-widget--list-item {
          &:nth-child(1) {
            grid-area: $gridArea1;
          }
          &:nth-child(2) {
            grid-area: $gridArea2;
          }
          &:nth-child(3) {
            grid-area: $gridArea3;
          }
        }
        .video-widget--list-item-video {
          aspect-ratio: $aspectRatio;
        }
      }
    }
  }
  /* < 1440 */
  @include media(LG) {
    &[data-width='6'] {
      .video-widget--list-item:nth-child(n + 2) {
        display: none;
      }
    }
    &[data-width='7'],
    &[data-width='8'] {
      &[data-height='4'],
      &[data-height='5'],
      &[data-height='6'],
      &[data-height='7'],
      &[data-height='8'] {
        .video-widget--list {
          display: flex;
        }
        .video-widget--list-item:nth-child(n + 3) {
          display: none;
        }
        .video-widget--list-item-video {
          aspect-ratio: $aspectRatio;
        }
      }
    }
    &[data-width='12'][data-height='6'] {
      .video-widget--list {
        display: grid;
        grid-template-columns: 72% 1fr;
        grid-template-rows: repeat(2, 1fr);
      }
      .video-widget--list-item {
        &:nth-child(1) {
          grid-area: $gridArea1;
        }
        &:nth-child(2) {
          grid-area: $gridArea2;
        }
        &:nth-child(3) {
          grid-area: $gridArea3;
        }
      }
      .video-widget--list-item-video {
        aspect-ratio: $aspectRatio;
      }
    }
  }
  /* < 1024 */
  @include media(MD) {
    &[data-width='1'] {
      .video-widget--list-item:nth-child(n + 2) {
        display: none;
      }
    }
    &[data-width='2'] {
      gap: 2.2rem;
      .video-widget--list-item:nth-child(n + 3) {
        display: none;
      }
    }
  }
  /* < 768 */
  @include media(SM) {
    .video-widget--title h2 {
      font-size: 2.4rem;
    }
    &[data-width='1'] {
      .video-widget--list-item:nth-child(n + 2) {
        display: none;
      }
    }
  }
  /* < 576 */
  @include media(XS) {
    .video-widget--title h2 {
      font-size: 2.4rem;
    }
    &[data-width='1'] {
      .video-widget--list-item:nth-child(n + 2) {
        display: none;
      }
    }
  }
}
