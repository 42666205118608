/** @format */

.news-widget {
  height: 100%;
  /* background-color: $bgWidget; */
  background: #fea500;
  /* color: white; */
  padding: 2.4rem;
  border-radius: 1.6rem;
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: 3.2rem;
  overflow: hidden;
  transition: 0.3s ease all;
  &--title {
    h2 {
      font-size: 3rem;
    }
  }
  &--search {
  }
  &--list-wrapper {
  }
  &--list {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }
  &--list-item {
    display: flex;
    gap: 1.6rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
    padding-bottom: 2.4rem;

    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }
  &--list-item-top {
    display: flex;
    align-items: center;
    gap: 1.2rem;
  }
  &--list-item-title {
    font-size: 1.8rem;
    font-weight: bold;
    @include textrow(1);
  }
  &--list-item-date-category {
    font-size: 1.3rem;
    display: flex;
    gap: 1rem;
  }
  &--list-item-date {
    text-wrap: nowrap;
    &:after {
      content: '•';
      display: inline-block;
      margin-left: 1rem;
    }
  }
  &--list-item-category {
    text-wrap: nowrap;
  }
  &--list-item-text {
    font-size: 1.8rem;
    @include textrow(3);
    line-height: 1.4;
    font-weight: 300;
  }
  &--list-empty {
    font-weight: bold;
  }
  &--link {
    margin-top: auto;
    font-weight: bold;
    background-image: url($arrow-right);
    background-repeat: no-repeat;
    background-position: left -4px;
    padding-left: 3rem;
    a,
    a:hover {
      /* color: white !important; */
    }
  }

  &[data-width='3'],
  &[data-width='4'],
  &[data-width='5'] {
    &[data-height='2'] {
      gap: 2.6rem;
      .news-widget--list-item-text {
        display: none;
      }
      .news-widget--list-item {
        padding-bottom: 1.2rem;
        &:nth-child(n + 2) {
          display: none;
        }
      }
    }
    &[data-height='3'] {
      .news-widget--list-item-text {
        @include textrow(2);
      }
      .news-widget--list-item:nth-child(n + 2) {
        display: none;
      }
    }
    &[data-height='4'] {
      .news-widget--list-item-text {
        @include textrow(1);
      }
      .news-widget--list-item:nth-child(n + 3) {
        display: none;
      }
    }
    &[data-height='5'] {
      .news-widget--list-item-text {
        @include textrow(3);
      }
      .news-widget--list-item:nth-child(n + 3) {
        display: none;
      }
    }
    &[data-height='6'] {
      .news-widget--list-item-text {
        @include textrow(2);
      }
      .news-widget--list-item:nth-child(n + 4) {
        display: none;
      }
    }
  }

  &[data-width='6'],
  &[data-width='7'],
  &[data-width='8'],
  &[data-width='9'] {
    .news-widget--list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2.4rem;
    }
    &[data-height='2'] {
      gap: 2.6rem;
      .news-widget--list-item {
        padding-bottom: 1.2rem;
      }
      .news-widget--list-item-text {
        display: none;
      }
      .news-widget--list-item:nth-child(n + 3) {
        display: none;
      }
    }
    &[data-height='3'] {
      .news-widget--list-item-text {
        @include textrow(2);
      }
      .news-widget--list-item:nth-child(n + 3) {
        display: none;
      }
    }
    &[data-height='4'] {
      .news-widget--list-item-text {
        @include textrow(1);
      }
      .news-widget--list-item:nth-child(n + 5) {
        display: none;
      }
    }
    &[data-height='5'] {
      .news-widget--list-item-text {
        @include textrow(4);
      }
      .news-widget--list-item:nth-child(n + 5) {
        display: none;
      }
    }
    &[data-height='6'] {
      .news-widget--list-item-text {
        @include textrow(2);
      }
      .news-widget--list-item:nth-child(n + 7) {
        display: none;
      }
    }
  }
}
