/** @format */

.academy-widget {
  height: 100%;
  background-color: $bgWidget;
  padding: 2.4rem;
  border-radius: 1.6rem;
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: 3.2rem;
  overflow: hidden;
  transition: 0.3s ease all;
  &--title {
    h2 {
      font-size: 3rem;
    }
  }
  &--link {
    margin-top: auto;
    font-weight: bold;
    background-image: url($arrow-right);
    background-repeat: no-repeat;
    background-position: left -4px;
    padding-left: 3rem;
  }
  &--tabs-wrapper {
  }
  &--tabs-tab {
    display: flex;
    gap: 16px;
    border-bottom: 1px solid #fea500;
    li {
      list-style: none;
      button {
        font-size: 1.8rem;
        border: 0;
        border-radius: 0;
        color: #000;
        padding: 12px 12px 19px;
        border-bottom: 3px solid transparent;
        text-transform: capitalize;
        font-weight: normal;
        &.active {
          color: black;
          border-color: #fea500;
          font-weight: bold;
        }
      }
    }
  }
  &--tabs-content {
    margin-top: 3.2rem;
  }
  &--list {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }
  &--item {
    display: flex;
    gap: 16px;
    padding-bottom: 2.4rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }
  &--item-image {
    width: 64px;
    height: 64px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.16);
  }
  &--item-info {
    flex: 1 1;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto;
    align-items: center;
    gap: 0.8rem;
  }
  &--item-title {
    font-weight: bold;
    font-size: 1.8rem;
    @include textrow(1);
  }
  &--item-date-location {
    font-size: 1.3rem;
    display: flex;
    gap: 1rem;
  }
  &--item-date {
    text-wrap: nowrap;
    &:after {
      content: '•';
      display: inline-block;
      margin-left: 1rem;
    }
  }
  &--item-location {
    text-wrap: nowrap;
  }
  &--item-text {
    grid-area: 2 / 1 / 3 / 3;
    font-weight: 300;
    font-size: 1.8rem;
    @include textrow(4);
  }
  &--list-empty {
    font-weight: bold;
  }

  &[data-width='3'] {
    .academy-widget--item-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    &[data-height='3'] {
      .academy-widget--item:nth-child(n + 2) {
        display: none;
      }
      .academy-widget--item-text {
        display: none;
      }
    }
    &[data-height='4'] {
      .academy-widget--item {
        flex-direction: column;
      }
      .academy-widget--item:nth-child(n + 2) {
        display: none;
      }
      .academy-widget--item-text {
        display: none;
      }
    }
    &[data-height='5'] {
      .academy-widget--item {
        flex-direction: column;
      }
      .academy-widget--item:nth-child(n + 2) {
        display: none;
      }
      .academy-widget--item-text {
        @include textrow(3);
      }
    }
    &[data-height='6'] {
      .academy-widget--item {
        flex-direction: column;
      }
      .academy-widget--item:nth-child(n + 3) {
        display: none;
      }
      .academy-widget--item-text {
        display: none;
      }
    }
  }
  &[data-width='4'],
  &[data-width='5'] {
    &[data-height='3'] {
      .academy-widget--item:nth-child(n + 2) {
        display: none;
      }
      .academy-widget--item-text {
        @include textrow(1);
      }
    }
    &[data-height='4'] {
      .academy-widget--item-text {
        @include textrow(3);
      }
      .academy-widget--item:nth-child(n + 2) {
        display: none;
      }
    }
    &[data-height='5'] {
      .academy-widget--item-text {
        @include textrow(2);
      }
      .academy-widget--item:nth-child(n + 3) {
        display: none;
      }
    }
    &[data-height='6'] {
      .academy-widget--item:nth-child(n + 3) {
        display: none;
      }
      .academy-widget--item-text {
        @include textrow(4);
      }
    }
  }
  &[data-width='6'],
  &[data-width='7'],
  &[data-width='8'],
  &[data-width='9'] {
    .academy-widget--list {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    &[data-height='3'] {
      .academy-widget--item:nth-child(n + 3) {
        display: none;
      }
      .academy-widget--item-text {
        @include textrow(1);
      }
    }
    &[data-height='4'] {
      .academy-widget--item-text {
        @include textrow(3);
      }
      .academy-widget--item:nth-child(n + 3) {
        display: none;
      }
    }
    &[data-height='5'] {
      .academy-widget--item-text {
        @include textrow(2);
      }
      .academy-widget--item:nth-child(n + 5) {
        display: none;
      }
    }
    &[data-height='6'] {
      .academy-widget--item:nth-child(n + 5) {
        display: none;
      }
    }
  }
}
