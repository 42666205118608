/** @format */

.profile {
  // margin-top: 5rem;
  .page_title {
    font: 500 40px/48px ContinentalStagSansW-M;
    font-family: 'ContinentalStagSansW-M', 'Noto Sans';
  }
  .page_subtitle {
    margin-top: 12px;
    font: 500 30px/36px ContinentalStagSansW-M;
    font-family: 'ContinentalStagSansW-M', 'Noto Sans';
    color: $black;
  }
  .sub_head {
    // margin-top: 2.5rem;
    margin-bottom: 32px;
    font: 500 24px/31px ContinentalStagSansW-M;
    font-family: 'ContinentalStagSansW-M', 'Noto Sans';
    &.mb-10 {
      margin-bottom: 10px;
    }
  }
  .profile_pic {
    .avatar-box,
    .avatarBox {
      //margin: auto;
      width: 12rem;
      height: 12rem;
      img {
        width: 12rem;
        height: 12rem;
        border-radius: 0;
      }
      .first-letter {
        font-size: 5rem;
      }
    }
  }
  .map-area {
    position: relative;
    width: 100%;
    height: 500px;
  }

  .search {
    position: absolute;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    max-width: 400px;
    z-index: 10;
  }

  .checkbox-text {
    display: inline-block;
    // font-size: 1.8rem;
    // font-weight: 900;
    // text-transform: uppercase;
    // vertical-align: middle;
    font: 300 18px/19px ContinentalStagSansW-L;
    font-family: 'ContinentalStagSansW-L', 'Noto Sans';
  }

  .search ul {
    width: 100%;
    font-size: 5rem;
  }

  .search input {
    padding: 0.5rem;
    width: 90%;
  }

  .map-search-wrapper {
    width: 100%;
    max-width: 399px;
    height: 56px;
    display: flex;
    //justify-content:center;
    align-items: center;
    background: #ffffff;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding-left: 24px;
    padding-right: 24px;
    z-index: 33 !important;
    position: absolute;
  }
  @media (min-width: 500px) {
    .map-search-wrapper {
      margin-left: 24px;
      margin-top: 24px;
    }
  }
  .map-search-box {
    flex: 1;
    background: #ffffff;
    border: unset;
    outline: unset;
    font-size: 18px;
    font-family: inherit;
    font-weight: 300;
  }
  @media (min-width: 768px) {
    .mapboxgl-ctrl-geocoder {
      min-width: 500px;
    }
  }

  .btn-search {
    margin-top: 10px;
    margin-left: 10px;
    position: absolute;
    right: 0rem;
  }

  .suggestions-box {
    width: 50rem;
    font-size: 1.7rem;
    margin-top: 1rem;
  }

  .btn-search::after {
    content: '';
    width: 24px;
    height: 24px;
    background-image: url('../../assets/icon/search-icon.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
  }
}
.map-text {
  margin-bottom: 32px;
  font: 300 18px/28px ContinentalStagSansW-L;
  font-family: 'ContinentalStagSansW-L', 'Noto Sans';
}

.page_error {
  color: #da7474;
  font-size: 12px;
  margin-top: 8px;
}

.errorMessage,
.successMessage {
  background-color: #daebe6;
  padding: 32px 32px 32px 64px;
  font-size: 18px;
  text-indent: -36px;
  margin-bottom: 48px;
  span {
    &:before {
      content: '';
      background: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_2906_21100)"><path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" fill="%23057855"/></g><defs><clipPath id="clip0_2906_21100"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>');
      width: 24px;
      height: 24px;
      display: inline-block;
      margin-right: 12px;
      vertical-align: middle;
    }
  }
}

.errorMessage {
  background: #ffe0e1;
  span {
    &:before {
      background: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_2906_20974)"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.73 3H8.27L3 8.27V15.73L8.27 21H15.73L21 15.73V8.27L15.73 3ZM19 14.9L14.9 19H9.1L5 14.9V9.1L9.1 5H14.9L19 9.1V14.9ZM12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17ZM13 7H11V14H13V7Z" fill="black"/></g><defs><clipPath id="clip0_2906_20974"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>');
    }
  }
}
.errorMessageInput {
  color: #da7474;
  padding: 8px 0;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
