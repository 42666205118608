/** @format */

.main_section {
  width: 100%;
  min-height: 100vh;
  @include flex;
  flex-direction: column;
  padding-top: 68px;
  @media (max-width: 768px) {
    padding-top: 48px;
  }
}

.content {
  // margin-top: $header-height;
  margin-bottom: 3rem;
  margin-top: 25px;
  min-height: calc(100vh - 228px - 111px);
  @media screen and (max-width: 991px) {
    margin-top: 30px;
    min-height: calc(100vh - 179px - 111px);
  }
}

.container {
  width: 100%;
  max-width: 1920px;
  @include center;
  padding: 0 1rem;
  transition: 0.4s ease;

  @include media(sx) {
    padding: 0 2rem;
  }

  @include media(xxl) {
    padding: 0 6rem;
  }
}

.silver-background {
  background-color: #d6d6d6;
  color: #000;
  min-height: 100px;
  display: flex;
  align-items: center;
  // margin-top: 8.2rem;
  h1 {
    font-size: 3rem;
    font-weight: 700;
  }
}

.content-container {
  height: 100%;
  width: 75%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;

  @include media(md) {
    width: 75%;
  }
}

.personal-data-container {
  // height: 100%;
  // display: flex;
  // flex-direction: column;
  margin-top: 136px;
  margin: 136px auto 200px;
  max-width: 880px;
  position: relative;

  // @include media(sx) {
  //   width: 90%;
  // }
  // @include media(xxs) {
  //   width: 100%;
  // }

  // @include media(xs) {
  //   width: 100%;
  // }
  // @include media(sm) {
  //   width: 100%;
  // }
  // @include media(md) {
  //   width: 100%;
  // }
  // @include media(lg) {
  //   width: 90%;
  // }
  // @include media(xl) {
  //   width: 50%;
  // }
  // @include media(xxl) {
  //   width: 50%;
  // }
}

.profile-container {
  height: 100%;
  width: 75%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;

  @include media(sx) {
    width: 90%;
  }
}

.content-title {
  color: #234d96;
  font-size: 3.2rem;
  margin: 2.2rem 0;
}

.content-block {
  color: #121212;
  margin-top: 4rem;
  margin-bottom: 1rem;
  letter-spacing: 0.5px;
  font-size: 16px;
  table {
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid #666;
    margin-bottom: 25px;
    td {
      width: 33.33%;
      border: 1px solid #666;
      padding: 10px;
    }
  }
  p {
    margin-bottom: 1.2rem;
    a {
      display: inline;
      &:hover {
        color: $primary-orange !important;
      }
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1.2rem;
    // color: #234d96;
    color: $primary-orange;
  }

  h2 {
    font-size: 32px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 18.72px;
  }
  h5 {
    font-size: 16px;
  }
  h6 {
    font-size: 13.28px;
  }
  ul {
    padding-left: 40px;
  }
  // img {
  //   // max-width: 420px;
  // }
}

.downloads-div {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-bottom: 50px;
  a {
    position: relative;
    left: 50px;
  }
  a:before {
    content: '';
    background-image: url('../../assets/imgs/download.png');
    width: 40px;
    height: 40px;
    position: absolute;
    left: -50px;
  }
}
/* News Article Styles */

.news-img-description {
  font-style: italic;
  margin: 1rem;
  font-size: 1.2rem;
}

.headline-two {
  margin: 20px 0;

  h2 {
    @extend .content-title;
    font-size: 2.4rem;
  }
}

.headline-three {
  margin: 20px 0;

  h2 {
    @extend .content-title;
    font-size: 1.8rem;
  }
}

.news-container-grid {
  display: grid;
  padding: 0 30px;
  grid-template-columns: 1fr;
  gap: 10px;

  @include media(lg) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 50px;
    padding: 30px;
  }

  .video-container {
    position: relative;

    .resp-iframe {
      position: relative;
    }

    .play-icon {
      @include icon($oval);
      top: 45%;

      &::after {
        content: '';
        background: url('../../assets/imgs/play_arrow.svg') no-repeat;
        background-size: 36px;
        position: absolute;
        display: inline-block;
        width: 45px;
        height: 68px;
        left: 25%;
        top: 15%;
      }
    }

    .video-title {
      background-color: #ffffff;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 2rem;

      @include media(md) {
        padding: 2rem 4rem;
      }

      p {
        color: #234d96;
        font-size: 1.2rem;
        text-align: center;
        font-weight: 700;
        text-transform: uppercase;

        @include media(md) {
          font-size: 1.6rem;
        }
      }

      // .play-icon {
      //   background-image: url();
      // }
    }
  }
}

.more-news {
  padding: 2rem 4rem;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
  border-radius: 8px;

  .more-title {
    font-weight: 700;
  }

  .news-container-link {
    margin: 15px 0;
    display: block;
  }

  h3 {
    font-size: 1.6rem;
    color: #234d96;
  }

  .more-news-paragraph {
    position: relative;

    &::after {
      @include icon($arrow-triangle-blue);
      position: absolute;
      bottom: 0;
      right: 0;
      content: '';
      height: 15px;
      width: 8px;
    }
  }
}

.news-container-grid {
  .contact-card {
    padding: 10px;
  }
}

.news-details-contact {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.services-container {
  .video-container {
    height: 80vh;

    .video-title {
      display: none;
    }
  }
}

.download-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 2rem 0;
}

.download-btn {
  width: 30px;
}

.download-box {
  display: flex;
  width: 100%;
}

.download-btn {
  width: 38px;
  margin-right: 1rem;
}

.document-name {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.document-size {
  font-size: 1rem;
}

.support-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  @include media(lg) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 50px;
    padding: 30px 0;
  }
}

.more-news-links {
  position: relative;
  min-width: 120px;
  width: 20%;
  display: flex;
  align-items: center;

  img {
    height: 15px;
    width: 8px;
    display: inline;
    margin-left: 10px;
  }
}

/* 404 Page */
.page-404-container {
  // height: 91vh;
  margin-top: 228px;
  // background: #f2f2f2;
  // padding: 50px;
  // background-color: rgba(214, 214, 214, 0.2) !important;
  // background-image: url(http://frontend.conticustport.alpha.1.k8.yourstage.rocks:31788/static/media/logo.dc189d90.svg);
  // background-repeat: no-repeat;
  // background-position: bottom right;
  // background-size: 250px 80px;
  // background-position-x: 95%;
  // max-width: 1440px;
  // margin: auto;
  // text-align: center;
  display: flex;
  justify-content: center;
}

.text-container-404 {
  text-align: center;
  margin-top: 100px;
  margin-bottom: 100px;
}
// .not-found-container {
//   position: absolute;
//   transform: translate(-50%, -50%);
//   top: 50%;
//   left: 50%;

//   img {
//     max-width: 500px;
//     margin: auto;
//     display: flex;
//   }
// }

.not-found-title {
  // color: #234d96;
  color: $color-red;
  // font-size: 40rem;
  font: 300 400px/420px ContinentalStagSansW-L;
  font-family: 'ContinentalStagSansW-L', 'Noto Sans';
  margin-bottom: 16px;
  // font-weight: normal;
  @media only screen and (max-width: 450px) {
    font-size: 20rem;
  }
}

.not-found-text {
  // margin-bottom: 4rem;
  // font-size: 3rem;
  // font-weight: 700;
  // text-transform: uppercase;
  font: 500 30px/36px ContinentalStagSansW-M;
  font-family: 'ContinentalStagSansW-M', 'Noto Sans';
  color: #333333;
  margin-bottom: 48px;
}

.btn-404 {
  // background-color: transparent;
  background: $primary-orange;
  // color: #fff !important;
  // box-shadow: 0px 2px 8px rgb(0 0 0 0, 16%);
  border-radius: 60px;
  // border: 1px solid #333;
  // font-size: 2rem;
  font: 500 18px/26px ContinentalStagSansW-M;
  font-family: 'ContinentalStagSansW-M', 'Noto Sans';
  padding: 12px 20px 10px;
  // margin-top: 2rem;
  // max-width: 400px;
}

.tutorial-video-container {
  position: relative;

  .video-container {
    height: 100%;
    border-radius: 0;

    &::before {
      background-image: none;
    }
  }

  .resp-iframe {
    position: relative;
  }

  .play-icon {
    display: block;
    width: 6.4rem;
    height: 6.4rem;
    background-image: url('../../assets/icon/icon-play.svg');
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    @include middle-box;
    cursor: pointer;
    z-index: 3;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }
}

.tutorial-video-container.playing {
  .play-icon {
    display: none;
  }
}

.tutorial-img {
  width: 400px;
}

.switch-button {
  width: 300px;

  .call-btn-blue {
    border-radius: 4px;
  }
}

.downloads-section {
  margin-bottom: 20px;

  h2 {
    font-size: 2.4rem;
    color: #234d96;
    margin-bottom: 20px;
  }
}

.download-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;

  @include media(sx) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
  }
}

.download-block {
  display: flex;
  align-items: center;

  img {
    width: 40px;
    height: 40px;
    margin-right: 20px;
  }
}

.download-details {
  .title {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }

  .size {
    font-size: 1.2rem;
  }
}

.know-more {
  color: #234d96;

  h2 {
    font-size: 2.4rem;
    margin-bottom: 20px;
  }
}

.contact-button {
  display: flex;
  align-items: center;
  margin: 20px 0;

  h2 {
    font-size: 1.8rem;
    color: #234d96;
  }

  img {
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }
}

.web-tutorials {
  position: relative;
  width: fit-content;

  a {
    text-decoration: underline;
    font-size: 1.4rem;
  }

  &::after {
    content: '';
    width: 0px;
    height: 0px;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid #234d96;
    position: absolute;
    top: -5px;
    right: -33px;
  }
}

.support-details {
  h2 {
    font-size: 2.4rem;
    color: #234d96;
  }
  margin: 40px 0 20px 0;

  .support-section {
    display: grid;
    grid-template-columns: 1fr;
    gap: 50px;
    margin: 20px 0;

    @include media(sx) {
      grid-template-columns: 1fr 1fr;
    }
  }
}

// .contact-tutorial {
//   display: flex;
//   align-items: center;
//   img {
//     width: 40px;
//     margin-bottom: -10px;
//   }
// }

// .contact-buttons {
//   display: flex;
//   .back-btn-blue {
//     display: flex;
//     margin-right: 30px;
//     align-items: center;
//     img {
//       width: 30px;
//       margin-right: 20px;
//     }
//   }
// }

// .tutorial-link {
//   text-decoration: underline;
//   position: relative;
//   &::after {
//     content: "";
//     position: absolute;
//     background-image: url("../../assets/imgs/chevron-down.svg");
//     background-size: 20px;
//     width: 20px;
//     height: 20px;
//     transform: rotate(270deg);
//     right: -30px;
//   }
// }

// donwload on public page

.content-block-download-wrapper {
  text-decoration: none;

  .download {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .image {
      width: 46px;
      height: 46px;
      object-fit: contain;
      margin-right: 12px;
    }

    p {
      margin: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      /* or 143% */

      letter-spacing: 0.16px;

      color: #000000;
    }
  }
}

.widget-container {
  margin: 0 auto;
  max-width: 1920px;
  padding: 0 52px;
  line-height: 1.2;
  /* background: #e6e0d8; */
  @include media(XL) {
    max-width: 1440px;
    padding: 0 30px;
  }
  @include media(LG) {
    max-width: 1024px;
    padding: 0 12px;
  }
  @include media(MD) {
    max-width: 768px;
    padding: 0 12px;
  }
  @include media(SM) {
    max-width: 544px;
    padding: 0 0;
  }
  @include media(XS) {
    max-width: 375px;
    padding: 0 0;
  }
}

.dashboard-widgets {
  margin-top: 32px;
  /* padding: 10px 16px 0; */
}
