/** @format */

.contactWidget {
  @include flex;
  flex-direction: column;
  padding: 2.4rem;
  border-radius: 1.6rem;
  width: 100%;
  height: 100%;
  /* background-image: linear-gradient(114.34deg, #196b96 0%, #2e90ac 98.73%); */
  background-color: #161616;
  &::before {
    content: '';
    position: absolute;
    top: 2.4rem;
    left: 2.4rem;
    width: 5.4rem;
    height: 5.4rem;
    background: #fea500;
    mix-blend-mode: darken;
  }
  img {
    display: inline-block;
    width: 2.4rem;
  }
  .card-body {
    margin-top: auto;
    color: $white;
    font-size: 1.8rem;
    line-height: 21px;
    .card-title {
      font-weight: 700;
    }
    .card-text {
      display: inline-block;
      margin-top: 1rem;
      font-weight: 400;
      font-size: 1.4rem;
      &:hover {
        color: rgba(255, 255, 255, 0.8) !important;
      }
    }
  }
  &.contact-link {
    .card-body {
      .card-title {
        text-transform: capitalize;
        font-size: 24px;
        margin-bottom: 16px;
      }
      .card-text {
        position: relative;
        padding-left: 16px;
        font-size: 14px;
        margin-top: 0;
        text-transform: uppercase;
        &:before {
          content: '';
          display: block;
          position: absolute;
          border: 6px solid #fff;
          width: 0;
          height: 0;
          top: 4px;
          left: 0;
          border-color: transparent transparent transparent #fff;
        }
      }
    }
  }
}
