/** @format */

.info-widget {
  background: #161616;
  padding: 2.4rem;
  border-radius: 1.6rem;
  overflow: hidden;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:after {
    content: '';
    position: absolute;
    top: 2.4rem;
    left: 2.4rem;
    width: 5.4rem;
    height: 5.4rem;
    background: #fea500;
    mix-blend-mode: darken;
  }
  img {
    width: 38px;
    height: 54px;
    margin-bottom: 15px;
  }

  .card-title-outer {
    flex: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  h2.card-title {
    text-transform: uppercase;
    color: #fff;
    font-weight: 700;
    margin-bottom: 20px;
    width: 100%;
    padding-right: 50px;
    font-size: 3rem;

    @include textrow(3);
  }

  p.card-text {
    color: #fff;
    font-size: 1.6rem;
    background-image: url($arrow-right-white);
    background-repeat: no-repeat;
    background-position: left -1px;
    padding-left: 3rem;
    margin-top: auto;
  }

  &[data-height='2'] {
    h2.card-title {
      @include textrow(1);
    }
  }
}
