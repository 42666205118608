/** @format */

.arrow {
  display: inline-block;
  width: 2.4rem;
  height: 2.4rem;
  padding: 0.6rem;
  &::before {
    @include icon($arrow);
    width: 100%;
    height: 100%;
  }
  &.arrow-up {
    transform: rotate(180deg);
  }
}
.arrow-triangle {
  cursor: pointer;
  display: inline-block;
  width: 1.3rem;
  height: 0.9rem;
  &::before {
    @include icon($arrow-triangle);
    width: 100%;
    height: 100%;
  }
  &.arrow-up {
    transform: rotate(180deg);
  }
}

//Avatar
.avatar-box {
  width: 5.4rem;
  height: 5.4rem;
  border-radius: 50%;
  overflow: hidden;
  // background-color: $blue;
  background-color: $primary-orange;
  position: relative;
  span.first-letter {
    color: $white;
    @include middle-box;
    font-size: 1.8rem;
    text-transform: uppercase;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

// badge

.badge {
  span {
    display: inline-block;
    // background-color: $blue;
    background: $color-light-blue;
    border-radius: 2px;
    padding: 0.8rem 1.6rem;
    vertical-align: middle;
    color: $white;
    // font-size: 1.4rem;
    // letter-spacing: 0.5px;
    // font-weight: 900;
    font: 500 14px/14px ContinentalStagSansW-M;
    font-family: 'ContinentalStagSansW-M', 'Noto Sans';
    // text-transform: uppercase;
  }

  @include media(md) {
    font-size: 1.4rem;
  }
}
