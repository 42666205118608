/** @format */

.widget-controllor {
  // background-color: $white;
  background-color: transparent;
  max-width: 50rem;
  position: fixed;
  top: 1.5rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1050;
  button {
    font-size: 1rem;
  }
}
