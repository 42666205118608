/** @format */

.events-widget {
  height: 100%;
  /* background-color: $bgWidget; */
  background: #fea500;
  padding: 2.4rem;
  border-radius: 1.6rem;
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: 3.2rem;
  overflow: hidden;
  transition: 0.3s ease all;
  &--title {
    h2 {
      font-size: 3rem;
      @include textrow(1);
    }
  }
  &--search {
  }
  &--list-wrapper {
  }
  &--list {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }
  &--list-item {
    display: flex;
    gap: 1.6rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);
    padding-bottom: 2.4rem;
  }
  &--list-item-image {
    width: 64px;
    height: 64px;
    background: rgba(0, 0, 0, 0.16);
    border-radius: 4px;
  }
  &--list-item-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }
  &--list-item-title {
    font-size: 1.8rem;
    font-weight: bold;
    @include textrow(1);
  }
  &--list-item-date-category {
    font-size: 1.3rem;
    display: flex;
    gap: 1rem;
  }
  &--list-item-date {
    text-wrap: nowrap;
    &:after {
      content: '•';
      display: inline-block;
      margin-left: 1rem;
    }
  }
  &--list-item-category {
    text-wrap: nowrap;
  }
  &--list-item-text {
    font-size: 1.8rem;
    @include textrow(2);
    font-weight: 300;
  }
  &--list-empty {
    font-weight: bold;
  }
  &--link {
    margin-top: auto;
    font-weight: bold;
    background-image: url($arrow-right);
    background-repeat: no-repeat;
    background-position: left -4px;
    padding-left: 3rem;
    a,
    a:hover {
      /* color: white !important; */
    }
  }

  &[data-width='3'] {
    &[data-height='2'] {
      .events-widget--list-item:nth-child(n + 2) {
        display: none;
      }
      .events-widget--list-item-text {
        display: none;
      }
    }
    &[data-height='3'] {
      .events-widget--list-item:nth-child(n + 2) {
        display: none;
      }
      .events-widget--list-item-text {
        @include textrow(1);
      }
    }
    &[data-height='4'] {
      .events-widget--list-item:nth-child(n + 3) {
        display: none;
      }
      .events-widget--list-item-title,
      .events-widget--list-item-text {
        @include textrow(1);
      }
    }
    &[data-height='5'] {
      .events-widget--list-item:nth-child(n + 3) {
        display: none;
      }
    }
    &[data-height='6'] {
      .events-widget--list-item:nth-child(n + 4) {
        display: none;
      }
    }
  }
  &[data-width='4'],
  &[data-width='5'],
  &[data-width='7'],
  &[data-width='8'],
  &[data-width='9'] {
    .events-widget--list-item-right {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-rows: auto auto;
      align-items: center;
    }
    .events-widget--list-item-text {
      grid-area: 2 / 1 / 3 / 3;
      @include textrow(2);
    }
  }

  &[data-width='4'],
  &[data-width='5'] {
    &[data-height='2'],
    &[data-height='3'] {
      .events-widget--list-item:nth-child(n + 2) {
        display: none;
      }
    }
    &[data-height='4'] {
      .events-widget--list-item:nth-child(n + 3) {
        display: none;
      }
    }
    &[data-height='5'] {
      .events-widget--list-item:nth-child(n + 4) {
        display: none;
      }
    }
    &[data-height='6'] {
      .events-widget--list-item:nth-child(n + 4) {
        display: none;
      }
      .events-widget--list-item-text {
        @include textrow(3);
      }
    }
  }
  &[data-width='6'][data-height='3'] {
    .events-widget--list-item:nth-child(n + 3) {
      display: none;
    }
  }
  &[data-width='6'],
  &[data-width='7'],
  &[data-width='8'],
  &[data-width='9'] {
    .events-widget--list {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    &[data-height='2'],
    &[data-height='3'] {
      .events-widget--list-item:nth-child(n + 3) {
        display: none;
      }
    }
    &[data-height='4'] {
      .events-widget--list-item:nth-child(n + 5) {
        display: none;
      }
      .events-widget--list-item-text {
        @include textrow(1);
      }
    }
    &[data-height='5'] {
      .events-widget--list-item:nth-child(n + 5) {
        display: none;
      }
      .events-widget--list-item-text {
        @include textrow(3);
      }
    }
    &[data-height='6'] {
      .events-widget--list-item:nth-child(n + 7) {
        display: none;
      }
      .events-widget--list-item-text {
        @include textrow(2);
      }
    }
  }
  &[data-width='7'],
  &[data-width='8'],
  &[data-width='9'] {
    &[data-height='3'] {
      .events-widget--list-item-text {
        @include textrow(3);
      }
    }
    &[data-height='4'] {
      .events-widget--list-item-text {
        @include textrow(2);
      }
    }
    &[data-height='5'] {
      .events-widget--list-item-text {
        @include textrow(4);
      }
    }
    &[data-height='6'] {
      .events-widget--list-item-text {
        @include textrow(3);
      }
    }
  }
}
