/** @format */

$star: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.6503 9.04L14.8103 8.62L12.9203 4.17C12.5803 3.36 11.4203 3.36 11.0803 4.17L9.19032 8.63L4.36032 9.04C3.48032 9.11 3.12032 10.21 3.79032 10.79L7.46032 13.97L6.36032 18.69C6.16032 19.55 7.09032 20.23 7.85032 19.77L12.0003 17.27L16.1503 19.78C16.9103 20.24 17.8403 19.56 17.6403 18.7L16.5403 13.97L20.2103 10.79C20.8803 10.21 20.5303 9.11 19.6503 9.04ZM12.0003 15.4L8.24032 17.67L9.24032 13.39L5.92032 10.51L10.3003 10.13L12.0003 6.1L13.7103 10.14L18.0903 10.52L14.7703 13.4L15.7703 17.68L12.0003 15.4Z" fill="%23234D96" /></svg>')
  center no-repeat;
$starHover: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.557 17.6975L15.707 20.2075C16.467 20.6675 17.397 19.9875 17.197 19.1275L16.097 14.4075L19.767 11.2275C20.437 10.6475 20.077 9.5475 19.197 9.4775L14.367 9.0675L12.477 4.6075C12.137 3.7975 10.977 3.7975 10.637 4.6075L8.74696 9.0575L3.91696 9.4675C3.03696 9.5375 2.67696 10.6375 3.34696 11.2175L7.01696 14.3975L5.91696 19.1175C5.71696 19.9775 6.64696 20.6575 7.40696 20.1975L11.557 17.6975Z" fill="%23234D96"/></svg>');

.tickets {
  font-size: 1.8rem;
  .btn-back {
    border: 0;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 1.8rem;
    color: $blue !important;
    margin: 24px 0 16px;
    font-weight: bold;
    text-transform: uppercase;
  }
  &-title {
    font-size: 3.2rem;
    text-transform: uppercase;
    @include media(md) {
      font-size: 4rem;
    }
  }
  &-sub-title {
    font-size: 2.4rem;
    color: $gray-dark;
    text-transform: uppercase;
    margin-top: 32px;
    @include media(md) {
      font-size: 3.2rem;
      margin-top: 12px;
    }
  }
}

.tickets-widget {
  .tickets-table {}
}
.tickets {
  .tickets-table {
    overflow-x: scroll;
    li {
      min-width: 1160px;
    }
    @include media(lg) {
      overflow-x: unset;
    }
  }
}
.tickets-table {
  display: grid;
  li {
    list-style: none;
    margin-top: 24px;
    border-top: 1px solid $gray-dark;
    position: relative;
    /* padding-top: 8px;
    padding-bottom: 24px;
    @include media(md) { */
    @include display-grid;
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
    align-items: center;
    grid-template-columns: 2fr 3fr 1fr 104px 1fr;
    &.in-page {
      grid-template-columns: 2fr 3fr 4fr 1fr 104px 1fr;
    }
    /* } */
    &:first-child {
      @include display-grid;
      border: 0;
      .attribute {
        font-weight: bold;
        line-height: 30px;
        padding: 15px 0 9px;
      }
    }
    &:nth-child(2) {
      @include media(md) {
        border-top: 0;
      }
    }
    .attribute {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding: 16px 0 0;
      max-width: calc(100vw - 90px);
      @include media(md) {
        padding: 16px 16px 16px 0;
      }
      .ticket-link {
        color: $blue !important;
      }
      &:last-child {
        text-align: right;
        position: absolute;
        bottom: 24px;
        right: 0;
        @include media(md) {
          position: relative;
          bottom: 0;
          display: flex;
          justify-content: flex-end;
        }
        .ticket-options {
          border: 0;
          padding: 0;
          width: 35px;
          min-width: 35px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      &:before {
        /* content: attr(data-name);
        font-weight: bold;
        display: block;
        margin-bottom: 4px;
        @include media(md) {
          display: none;
        } */
      }
    }
  }
}

.status-badge {
  font-size: 1.2rem;
  padding: 10px 16px;
  border-radius: 0.5rem;
  color: white;
  text-align: center;
  display: inline-block;
  margin-top: 8px;
  /* @include media(md) { */
  margin-top: 0;
  width: 100%;
  padding: 10px 0;
  /* } */
  /* &--in_progress {
    background-color: $vdo-blue;
  } */
  /* &--on_hold {
    background-color: $warning;
  } */
  /* &--closed {
    background-color: $green;
  } */
  &--in_progress {
    background-color: #fea500;
    color: black;
  }
  &--on_hold {
    background-color: #fea500;
    color: black;
  }
  &--closed {
    background-color: $gray-dark;
  }
  &--submitted {
    background-color: #f4cf9b;
    color: black;
  }
  &--canceled {
    background-color: transparent;
    border: 1px solid $gray-dark;
    color: $gray-dark;
  }
}

button.close-button {
}

.create-ticket-body {
  margin-bottom: 24px;
  color: black;
  font-size: 18px;
  line-height: 1.6;
  .create-ticket-title {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 16px;
    font-size: 24px;
    @include media(md) {
      font-size: 40px;
    }
    &--cancel {
      max-width: 420px;
      font-size: 20px;
      @include media(md) {
        font-size: 32px;
      }
    }
  }
  .create-ticket-subTitle {
    font-size: 24px;
  }
  .create-ticket-id {
    font-size: 24px;
  }
  .contact-person-heading {
    border-top: 1px solid $vdo-gray;
    font-weight: bold;
    padding-top: 8px;
  }
  .contact-person {
    display: flex;
    margin-top: 16px;
    flex-direction: column;
    margin-bottom: 16px;
    @include media(md) {
      flex-direction: row;
      margin-bottom: 0;
    }
    .contact-person-left {
      flex: 1;
    }
    .contact-person-right {
      flex: 1;
    }
  }
  .contact-person-checkbox {
    margin: 40px 0 32px;
    &-flex {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    input[type='checkbox'] {
      border: 2px solid black;
      border-radius: 5px;
      appearance: none;
      cursor: pointer;
      flex: 0 0 16px;
      height: 16px;
      @include media(md) {
        flex: 0 0 24px;
        height: 24px;
      }
      &:checked {
        background-color: $vdo-blue;
        border: 2px solid white;
        box-shadow: 0 0 0px 2px $vdo-blue;
      }
    }
    label {
      cursor: pointer;
    }
  }
  .create-ticket-selections {
    & > div:nth-child(2){
      @include media(md) {
        padding: 0 16px;
      }
    }
  }
  .create-ticket-mod-selections {
    @include media(md) {
      gap: 24px;
    }
    & > div {
      @include media(md) {
        flex-basis: calc(33.333333% - 16px);
      }
    }
  }
  .create-ticket-text-field {
    width: 100%;
    .MuiInput-underline:not(.Mui-disabled):before,
    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-width: 2px;
      border-color: black;
    }
    .MuiInputLabel-formControl {
      font-size: 18px;
      &:not(.Mui-error) {
        color: black;
      }
    }
    .MuiFormLabel-root.Mui-focused:not(.Mui-error) {
      color: #234d96;
    }
    .MuiInput-underline:not(.Mui-error):after {
      border-color: #234d96;
    }
    .MuiInput-root {
      input {
        font-size: 18px;
        &:not(.Mui-error) {
          color: #000;
        }
      }
      &.Mui-error input {
        color: #f44336;
      }
    }
    &-date {
      input::-webkit-datetime-edit {
        opacity: 0;
      }
      .MuiFormLabel-filled ~ div input::-webkit-datetime-edit {
        opacity: 1;
      }
      input[type='date' i]::-webkit-calendar-picker-indicator {
        cursor: pointer;
      }
    }
    .MuiInput-underline.Mui-disabled:before,
    .MuiInput-underline:hover.Mui-disabled:before {
      border-width: 2px;
      border-color: $gray;
      border-bottom-style: solid;
    }
    .MuiInputLabel-formControl.Mui-disabled,
    .MuiInput-underline.Mui-disabled input {
      color: $gray;
    }
  }
  .product-details {
    border-top: 1px solid $vdo-gray;
    padding-top: 8px;
    .product-details-heading {
      font-weight: bold;
    }
    // select {
    //   width: 100%;
    //   font-size: 18px;
    //   border: 0;
    //   padding: 0;
    //   font-family: 'Lato', 'Noto Sans', sans-serif;
    //   margin-top: 4px;
    // }
  }
  .product-detail-input {
    font-size: 1.4rem;
    margin-top: 16px;
    width: 100%;
    .MuiInput-input {
      border: 0;
      border-bottom: 2px solid $vdo-gray-dark;
    }
    .Mui-focused {
      color: $vdo-blue;
      &:after {
        border-color: $vdo-blue;
      }
    }
  }
  .create-ticket-textarea {
    font-size: 1.8rem;
    margin-top: 20px;
    width: 100%;
    textarea {
      font-size: 1.8rem;
      line-height: 1.5;
      color: #000;
    }
    .MuiInputLabel-outlined {
      /* font-size: 1.8rem; */
      &:not(.Mui-error) {
        color: black;
      }
    }
    .MuiOutlinedInput-notchedOutline {
      border-radius: 10px;
      border: 2px solid #000;
    }
    .Mui-focused {
      color: $vdo-blue;
      .MuiOutlinedInput-notchedOutline {
        border-color: $vdo-blue;
      }
    }
    &:hover :not(.Mui-error) .MuiOutlinedInput-notchedOutline {
      border: 2px solid #000;
    }
  }
  .product-detail-textarea {
    font-size: 1.4rem;
    /* margin-top: 60px; */
    /* height: 215px; */
    .MuiOutlinedInput-notchedOutline {
      border-radius: 10px;
      border: 2px solid $vdo-gray-dark;
    }
    .Mui-focused {
      color: $vdo-blue;
      .MuiOutlinedInput-notchedOutline {
        border-color: $vdo-blue;
      }
    }
  }
  .create-ticket-btns-step-1 {
    display: flex;
    gap: 16px;
    justify-content: flex-start;
    margin-top: 32px;
    flex-direction: column;
    button {
      width: 100%;
    }
    @include media(md) {
      flex-direction: row;
      button {
        width: auto;
      }
    }
  }
  .create-ticket-btns {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    margin-top: 32px;
    flex-wrap: wrap;
    @include media(md) {
      flex-wrap: nowrap;
    }
    button.border-only {
      flex: 1;
      justify-content: center;
      @include media(md) {
        flex: 0 0 auto;
        justify-content: space-between;
      }
    }
    button.btn-border {
      text-transform: uppercase;
      font-size: 18px;
      height: 54px;
      border-width: 2px;
      border-radius: 100px;
      padding: 0 24px;
      &:disabled {
        opacity: 0.5;
      }
    }
    .btn-restart {
      /* padding: 0; */
      border: 0;
      color: $vdo-blue;
      margin-right: auto;
      width: 100%;
      font-size: 1.6rem;
      @include media(md) {
        width: auto;
        font-size: 2.2rem;
      }
      &:disabled {
        opacity: 0.5;
      }
    }
    .btn-prev {
      margin-left: auto;
    }
  }
  .cancel-create-ticket-btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 32px;
    gap: 16px;
    flex-direction: column;
    @include media(md) {
      flex-direction: row;
    }
    button {
      /* text-transform: uppercase; */
      font-size: 16px;
      height: 54px;
      @include media(md) {
        font-size: 18px;
      }
    }
    .btn-no {
      /* padding: 0; */
      /* border: 0; */
      /* color: $vdo-gray; */
    }
    .btn-yes {
      border-width: 2px;
      border-radius: 100px;
      padding: 0 24px;
    }
  }
  .create-ticket-status {
    text-align: center;
    margin-top: 24px;
    padding-bottom: 24px;
    @include media(md) {
      margin-top: 80px;
      padding-bottom: 36px;
    }
    .status-heading {
      font-size: 24px;
      font-weight: bold;
      text-transform: uppercase;
      @include media(md) {
        font-size: 32px;
      }
    }
    .ticket-number {
      margin-top: 24px;
    }
    .status-content {
      margin-top: 30px;
      white-space: pre-line;
    }
    .status-btn {
      margin-top: 24px;
      @include media(md) {
        margin-top: 50px;
      }
      button {
        height: 54px;
        font-size: 1.8rem;
        padding: 0 24px;
      }
    }
  }
  .page_error {
    font-size: 1.6rem;
    color: red;
  }
}

.ticket-filter {
  margin: 28px 0 24px;
  ul {
    border-bottom: 1px solid $gray-dark;
    display: flex;
    overflow: hidden;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(90, 90, 90);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0);
    }
    li {
      list-style: none;
      button {
        padding: 16px 24px;
        border: 0;
        font-size: 18px;
        font-weight: normal;
        border-radius: 0;
        text-transform: capitalize;
        color: $gray-dark;
        transition: none;
        white-space: nowrap;
      }
      &.active {
        button {
          border-bottom: 3px solid $green;
          color: black;
        }
      }
    }
  }
}

.table-pagination {
  display: flex;
  margin-top: 32px;
  justify-content: flex-start;
  @include media(mg) {
    justify-content: flex-end;
  }
  .MuiTablePagination-root {
    font-size: 1.8rem;
    color: black;
    border: 0;
    .MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.MuiToolbar-gutters {
      padding-left: 0;
    }
    .MuiSelect-icon {
      color: black;
    }
    .MuiTypography-body2 {
      font-size: 1.8rem;
    }
    .MuiTypography-root.MuiTablePagination-caption.MuiTypography-body2.MuiTypography-colorInherit:nth-last-child(2) {
      display: none;
      @include media(md) {
        display: block;
      }
    }
  }
}

.progress-bar {
  margin-top: 24px;
  .number {
    width: 32px;
    height: 32px;
    line-height: 32px;
    background-color: $gray-dark;
    color: white;
    text-align: center;
    border-radius: 100px;
    font-size: 1.2rem;
    z-index: 9;
    position: absolute;
    top: 0;
    left: 0;
    @include media(md) {
      position: relative;
      font-size: 1.4rem;
    }
  }
  .status {
    font-size: 1.8rem;
    line-height: 1;
    margin: 0 0 2px 0;
    @include media(md) {
      margin: 10px 0 5px 0;
    }
  }
  .date {
    font-size: 1.2rem;
    color: $gray-dark;
  }
  ul {
    display: flex;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 24px;
    padding-bottom: 45px;
    @include media(md) {
      flex-direction: row;
      padding-bottom: 0;
      row-gap: 70px;
    }
    &:before {
      content: '';
      position: absolute;
      height: calc(100% - 45px);
      background-color: $gray-dark;
      width: 3px;
      top: 0;
      left: 15px;
      @include media(md) {
        height: 3px;
        background-color: $gray-dark;
        width: 100%;
        top: 15px;
        left: 0;
      }
    }
    li {
      text-align: center;
      list-style: none;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      position: relative;
      padding-left: 45px;
      min-height: 32px;
      @include media(md) {
        min-height: 0;
        padding-left: 0;
        align-items: center;
        justify-content: flex-start;
        gap: unset;
      }
      &:before {
        content: '';
        height: 100vh;
        background-color: $green;
        width: 3px;
        position: absolute;
        bottom: 50%;
        left: 15px;
        opacity: 0;
        z-index: 2;
        @include media(md) {
          bottom: auto;
          left: auto;
          height: 3px;
          width: 100vw;
          top: 15px;
          right: 50%;
        }
      }
      &:first-child {
        @include media(md) {
          align-items: flex-start;
        }
      }
      &:last-child {
        @include media(md) {
          align-items: flex-end;
        }
      }
      &.active {
        &:before {
          opacity: 1;
        }
        .number {
          background-color: $green;
        }
        &.on-hold {
          position: absolute;
          left: 0;
          bottom: calc(16.67% + 32px);
          @include media(md) {
            left: auto;
            bottom: auto;
            top: 0;
            right: calc(16.67% - 30px);
          }
          &:before {
            background-color: $warning;
            z-index: 1;
          }
          .number {
            background-color: $warning;
          }
        }
      }
    }
  }
  &.progress-bar-in-progress {
    .submitted .number {
      background-color: rgb(8, 196, 118);
    }
  }
  &.progress-bar-pending {
    .submitted .number,
    .in-progress .number {
      background-color: rgb(8, 196, 118);
    }
  }
  &.progress-bar-resolved {
    .submitted .number,
    .in-progress .number,
    .in-review .number {
      background-color: rgb(8, 196, 118);
    }
  }
  &.progress-bar-closed {
    .submitted .number,
    .in-progress .number,
    .in-review .number,
    .on-hold .number {
      background-color: rgb(8, 196, 118);
    }
  }
}

.ticket-main {
  margin-top: 40px;
  margin-bottom: 80px;
  @include media(md) {
    margin-top: 84px;
    margin-bottom: 200px;
    display: flex;
    gap: 180px;
    justify-content: space-between;
  }
  .ticket-info {
    @include media(md) {
      max-width: 50%;
    }
  }
  .ticket-title,
  .ticket-description {
    word-break: break-word;
    border-top: 1px solid $gray-dark;
    line-height: 1.6;
    white-space: pre-line;
    padding: 24px 0 24px;
    @include media(md) {
      padding: 32px 0 24px;
    }
    p:first-child {
      margin-bottom: 12px;
    }
  }
  .ticket-info {
  }
  .ticket-shadow-box {
    background-color: white;
    padding: 24px;
    border-radius: 0.5rem;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
  }
  .ticket-right-side {
    margin-top: 64px;
    @include media(md) {
      margin-top: 0;
      width: 50%;
    }
  }
  .ticket-rating {
    p > strong {
      display: block;
      font-size: 2.4rem;
      font-weight: bold;
    }
    p {
      margin-bottom: 24px;
    }
    ul {
      display: flex;
      align-items: center;
      li {
        list-style: none;
        button {
          &.rating-star {
            border: 0;
            padding: 0;
            border-radius: 0;
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.6503 9.04L14.8103 8.62L12.9203 4.17C12.5803 3.36 11.4203 3.36 11.0803 4.17L9.19032 8.63L4.36032 9.04C3.48032 9.11 3.12032 10.21 3.79032 10.79L7.46032 13.97L6.36032 18.69C6.16032 19.55 7.09032 20.23 7.85032 19.77L12.0003 17.27L16.1503 19.78C16.9103 20.24 17.8403 19.56 17.6403 18.7L16.5403 13.97L20.2103 10.79C20.8803 10.21 20.5303 9.11 19.6503 9.04ZM12.0003 15.4L8.24032 17.67L9.24032 13.39L5.92032 10.51L10.3003 10.13L12.0003 6.1L13.7103 10.14L18.0903 10.52L14.7703 13.4L15.7703 17.68L12.0003 15.4Z" fill="%23234D96" /></svg>')
              center no-repeat;
            background-size: 24px 24px;
            &:hover {
              background-image: $starHover;
            }
          }
          &.rating-submit {
            margin-left: 16px;
            font-size: 1.6rem;
            padding: 0 16px;
          }
        }
      }
      &:has(li:nth-child(2):hover) li:nth-child(1) .rating-star {
        background-image: $starHover;
      }
      &:has(li:nth-child(3):hover) li:nth-child(-n + 2) .rating-star {
        background-image: $starHover;
      }
      &:has(li:nth-child(4):hover) li:nth-child(-n + 3) .rating-star {
        background-image: $starHover;
      }
      &:has(li:nth-child(5):hover) li .rating-star {
        background-image: $starHover;
      }
    }
  }
  .ticket-comments {
    margin-top: 24px;
    /* overflow: hidden; */
    /* overflow-y: scroll; */
    /* max-height: calc(100vh - 500px); */
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(90, 90, 90);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0);
    }
    .comment {
      padding: 16px 0;
      @include media(md) {
        padding: 30px 0;
      }
      &:not(:last-child) {
        border-bottom: 1px solid $gray-dark;
      }
      .comment-header {
        display: flex;
        justify-content: flex-end;
        font-size: 1.6rem;
        .comment-name {
          display: flex;
          align-items: center;
          gap: 12px;
          img {
            border-radius: 100px;
            width: 32px;
            height: 32px;
          }
          font-weight: bold;
        }
        .comment-date {
          color: $gray-dark;
        }
      }
      .comment-detail {
        margin-top: 16px;
        line-height: 1.6;
        word-break: break-word;
        @include media(md) {
          margin-top: 30px;
        }
      }
    }
  }
  .comment-btn {
    display: flex;
    justify-content: flex-end;
    button {
      border: 0;
      border-radius: 0;
      padding: 0 0 0 18px;
      font-size: 1.8rem;
      color: $blue;
      background: url($arrow-triangle-blue) left center no-repeat;
    }
  }
}

.visually-hidden-input {
  word-break: break-all;
  @include media(md) {
    word-break: unset;
  }
  label {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  input {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    width: 1px;
  }
}

.file-list {
  display: flex;
  gap: 8px;
  flex-direction: column;
  margin-top: 10px;
}
.file-item {
  border: 1px dashed #333;
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 1.6rem;
  display: flex;
  justify-content: space-between;
  button {
    padding: 0;
    border: 0;
    border-radius: 0;
    svg {
      path {
        fill: black;
      }
    }
  }
}
