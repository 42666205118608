/** @format */

$vdo-blue: #234d96;
$vdo-gray: #d6d6d6;
$vdo-gray-dark: #868686;

.card {
  @include display-grid;

  @include media(xl) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  grid-template-columns: 1fr;
  grid-gap: 30px;
  padding: 30px 0;
  background-color: transparent;
}

.card-type-1 {
  // box-shadow: 0px 15px 25px 0px #00000026;
  // background: linear-gradient(114.34deg, #196b96 0%, #2e90ac 98.73%);
  background: linear-gradient(25.53deg, $primary-orange 0%, #ffca79 100%);
  border-radius: 2px;
  padding: 2.4rem;
  // border-radius: 10px;
  position: relative;
  height: 100%;
  img {
    width: 38px;
    height: 54px;
    margin-bottom: 58px;
  }

  h2.card-title {
    // text-transform: uppercase;
    // color: #fff;
    font-weight: 700;
    // margin-bottom: 20px;
    width: 100%;
    padding-right: 50px;
    font-size: 1.8rem;
    font-family: 'ContinentalStagSansW-M', 'Noto Sans';
    font-weight: 500-M;
    @include media(sx) {
      font-size: 2.4rem;
      padding-right: 0;
    }
    @include media(xlg) {
      font-size: 30px;
      line-height: 36px;
    }
  }

  p.card-text {
    // color: #fff;
    position: absolute;
    bottom: 25px;
    font-weight: 400;
    line-height: 22px;
    word-break: break-word;
    font-size: 1.4rem;
    @include flex;
    align-items: center;
    @include media(sx) {
      font-size: 1.6rem;
    }
    @include media(md) {
      font-size: 18px;
      line-height: 21px;
    }
    &::before {
      @include icon('../../assets/icon/chevron-right.svg');
      background-size: auto;
      display: block;
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
}
.card-type-4 {
  @include flex;
  flex-direction: column;
  padding: 2.4rem;
  // border-radius: 1rem;
  width: 100%;
  height: 100%;
  // background-image: linear-gradient(114.34deg, #196b96 0%, #2e90ac 98.73%);
  background: linear-gradient(25.53deg, #fea500 0%, #ffca79 100%);
  border-radius: 2px;
  img {
    display: inline-block;
    width: 2.4rem;
  }
  .card-body {
    margin-top: auto;
    color: $black;
    font-size: 1.8rem;
    line-height: 21px;
    .card-title {
      font-weight: 700;
    }
    .card-text {
      display: inline-block;
      margin-top: 1rem;
      font-weight: 400;
      font-size: 1.4rem;
    }
  }
}
/* Video Component */
.video-container {
  display: block;
  border-radius: 2px;
  position: relative;
  overflow: hidden;
  height: 400px;
  width: 100%;

  @include media(lg) {
    height: 100%;
  }

  .badge {
    position: absolute;
    z-index: 2;
    top: 2rem;
    left: 2rem;
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    // background-image: linear-gradient(180deg, rgba(14, 47, 104, 0) 12.5%, #0e2f68 94.79%);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 43.64%, rgba(0, 0, 0, 0.8) 100%);
    z-index: 2;
  }
  .video-title {
    position: absolute;
    bottom: 2.4rem;
    left: 2.4rem;
    color: #ffffff;
    z-index: 2;
    transition: 0.3s ease;

    p {
      font: 500 30px/36px ContinentalStagSansW-M;
      font-family: 'ContinentalStagSansW-M', 'Noto Sans';
      // @include media(sx) {
      //   font-size: 2.4rem;
      //   padding-right: 0;
      // }
      // @include media(xl) {
      //   font-size: 3.2rem;
      // }
    }
  }
  .play-icon {
    display: block;
    width: 6.4rem;
    height: 6.4rem;
    background-image: url('../../assets/icon/play-icon.svg');
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    @include middle-box;
    cursor: pointer;
    z-index: 3;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
}
.video-container.playing {
  &::before {
    display: none;
  }
  .play-icon {
    display: none;
  }
  .video-title {
    transform: translateY(20rem);
  }
}

.resp-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 1rem;
}

/* News Component */

.news-grid {
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 2px;
}
.news-container {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 2.4rem;
}
.news-box-0 {
  margin-top: 1rem;
}
.news-box {
  padding: 2.4rem 0;
  &:not(:last-child) {
    border-bottom: 1px solid $vdo-gray;
  }
  .news-link-wrap {
    flex-direction: column;
    align-items: initial;
  }
}
.news-h1 {
  // color: $vdo-blue;
  color: $black;
  margin-bottom: 1.2rem;
  width: 30%;
  padding-right: 16px;
  font: 500 24px/31px ContinentalStagSansW-M;
  font-family: 'ContinentalStagSansW-M', 'Noto Sans';
  // @include media(lg) {
  //   font-size: 3.2rem !important;
  // }

  // @include media(sx) {
  //   font-size: 2.4rem;
  // }
}

.news-p1 {
  color: #333333;
  color: $black;
  // width: calc(100% - 10px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 1.6rem;
  font: 300 18px/28px ContinentalStagSansW-L;
  font-family: 'ContinentalStagSansW-L', 'Noto Sans';
  // @include media(lg) {
  //   width: calc(100% - 20px) !important;
  // }
}

.subnews-h1 {
  @extend .news-h1;
  @include media(lg) {
    font-size: 1.8rem !important;
  }

  @include media(sx) {
    font-size: 1.6rem;
  }
}

.news-headline {
  margin-top: 80px;
  border-bottom: 1px solid $vdo-gray;
}

// Tickets Widget
.tickets-widget {
  font-size: 1.8rem;
  height: 100%;
  .tickets-container {
    background: white;
    padding: 16px 24px 24px;
    border-radius: 1.6rem;
    /* box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1); */
    height: 100%;
  }
  .tickets-header {
    display: flex;
    justify-content: space-between;
  }
  .new-ticket-btn {
    display: none;
    @include media(md) {
      display: block;
    }
    &-sp {
      display: block;
      margin-top: 24px;
      @include media(md) {
        display: none;
      }
    }
  }
  .tickets-link-all {
    gap: 8px;
    margin-top: 32px;
    display: none;
    @include media(md) {
      display: flex;
    }
    span {
      color: $vdo-blue;
      font-weight: bold;
    }
  }
}

.card-type-3 {
  height: 100%;
  position: relative;
  border-radius: 2px;
  // box-shadow: 0px 15px 25px 0px #00000026;
  background-color: $white;
  .card-top {
    width: 100%;
    height: 19.3rem;
    @include media(md) {
      height: 13.8rem;
    }
    @include media(xl) {
      height: 16.1rem;
    }
    @include media(lg) {
      height: 18rem;
    }
    @include media(xxl) {
      height: 23.9rem;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
  .badge {
    position: absolute;
    // top: -15%;
    top: 24px;
    left: 24px;
    z-index: 1;
  }
  .badge.highlight span {
    background-color: $color-light-green;
  }
}

/* temporary grid */
.temp-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 30px;
  gap: 30px;
  /* margin: 30px 0; */
  background: #fff;
  padding: 30px;
  margin: 10px 0;
  border-radius: 10px;
}

.card-3-link {
  display: flex;
  align-items: center;
  font: 400 18px/21px ContinentalStagSansW;
  font-family: 'ContinentalStagSansW', 'Noto Sans';
  color: $primary-orange;
  // text-transform: uppercase;
  position: absolute;
  bottom: 2.4rem;
  padding: 0 2.4rem;
  &::before {
    content: '';
    @include icon('../../assets/icon/chevron-right.svg');
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 0.8rem;
    background-size: auto;
  }
}
.card-3-link.highlight::before {
  @include icon('../../assets/icon/chevron-right.svg');
  display: inline-block;
  background-size: auto;
}

.card-bottom {
  position: relative;
  padding: 24px 24px 80px;
  // border-radius: 0 0 10px 10px;

  h3 {
    // font-size: 2rem;
    font: 500 18px/28px ContinentalStagSansW-M;
    font-family: 'ContinentalStagSansW-M', 'Noto Sans';
    // padding: 10px 0 20px 5px;
  }
}

/* Contact Card */
.contact-card {
  background: #fea500;
  border-radius: 10px;
  padding: 24px;

  .contact-title {
    color: #fff;
    font-size: 1.8rem;
    margin-bottom: 10px;
    font-weight: 700;
  }

  .contact-text {
    font-size: 1.8rem;
    color: #fff;
    font-weight: 400;
  }
  img {
    width: 30px;
    height: 30px;
    margin-bottom: 20px;
  }
}

.dynamic-widget-card {
  overflow: auto;
  background-color: $bgWidget;
  border-radius: 1.6rem;
  position: relative;
  padding: 2.4rem;
  height: 100%;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $black;
  ::part(card-heading) {
    // font-style: normal;
    // font-weight: bold;
    // font-size: 18px;
    // line-height: 22px;
    font: 500 18px/28px ContinentalStagSansW-M;
    font-family: 'ContinentalStagSansW-M', 'Noto Sans';
    color: red;
    // color: #333333;
  }

  a {
    color: #fea500;
  }
}

.dynamic-widget-card .hydrated :host(.card-heading) {
  color: red !important;
}

.dynamic-widget-card_profile {
  overflow: auto;
  background: #ffffff;
  position: relative;
  height: 100%;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #333333;

  .card-heading {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #333333;
  }
}
