/** @format */

.extranet-widget {
  height: 100%;
  background-color: $bgWidget;
  padding: 2.4rem;
  border-radius: 1.6rem;
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: 3.2rem;
  overflow: hidden;
  transition: 0.3s ease all;
  &--title {
    h2 {
      font-size: 3rem;
    }
  }
  &--search {
    input {
      border: none;
      border-bottom: 1px solid black;
      height: 30px;
      width: 100%;
      font-size: 1.8rem;
      background-color: transparent;
      background-image: url($search);
      background-repeat: no-repeat;
      background-position: right center;
      &:focus,
      &:focus-visible {
        border: none;
        border-bottom: 1px solid black;
        outline: none;
      }
    }
  }
  &--list-wrapper {
    background: rgba(0, 0, 0, 0.05);
    padding: 2.4rem;
    border-radius: 0.8rem;
    h3 {
      font-size: 1.8rem;
    }
  }
  &--list {
    margin-top: 3.5rem;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }
  &--list-empty {
    font-size: 1.3rem;
  }
  &--list-item {
    display: grid;
    grid-template-columns: 64px 1fr;
    grid-template-rows: auto auto;
    column-gap: 1.6rem;
    row-gap: 0.8rem;
  }
  &--list-item-image {
    width: 64px;
    height: 64px;
    background: #cdcdcd;
    border-radius: 4px;
    grid-area: 1/1/3/2;
  }
  &--list-item-title {
    grid-area: 1/2/2/3;
    font-weight: bold;
    @include textrow(2);
  }
  &--list-item-date-category {
    grid-area: 2/2/3/3;
    display: flex;
    gap: 1rem;
    font-size: 1.3rem;
  }
  &--list-item-date {
    text-wrap: nowrap;
    &:after {
      content: '•';
      display: inline-block;
      margin-left: 1rem;
    }
  }
  &--list-item-category {
    text-wrap: nowrap;
  }
  &--link {
    margin-top: auto;
    font-weight: bold;
    background-image: url($arrow-right);
    background-repeat: no-repeat;
    background-position: left -4px;
    padding-left: 3rem;
  }

  /* START WIDGET RESIZE */
  &[data-width='3'] {
    &[data-height='2'],
    &[data-height='3'],
    &[data-height='4'] {
      .extranet-widget--list-item:nth-child(n + 2) {
        display: none;
      }
    }
    &[data-height='5'] {
      .extranet-widget--list-item:nth-child(n + 3) {
        display: none;
      }
    }
    &[data-height='6'] {
      .extranet-widget--list-item:nth-child(n + 4) {
        display: none;
      }
    }
  }
  &[data-width='4'] {
    &[data-height='2'],
    &[data-height='3'],
    &[data-height='4'] {
      .extranet-widget--list-item:nth-child(n + 2) {
        display: none;
      }
    }
    &[data-height='5'] {
      .extranet-widget--list-item:nth-child(n + 3) {
        display: none;
      }
    }
    &[data-height='6'] {
      .extranet-widget--list-item:nth-child(n + 4) {
        display: none;
      }
    }
  }
  &[data-width='5'] {
    &[data-height='2'],
    &[data-height='3'],
    &[data-height='4'],
    &[data-height='5'],
    &[data-height='6'] {
      .extranet-widget--list-item {
        display: grid;
      }
    }
    &[data-height='2'],
    &[data-height='3'],
    &[data-height='4'] {
      .extranet-widget--list {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
      .extranet-widget--list-item:nth-child(n + 3) {
        display: none;
      }
    }
    &[data-height='5'] {
      .extranet-widget--list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
      }
      .extranet-widget--list-item:nth-child(n + 5) {
        display: none;
      }
    }
    &[data-height='6'] {
      .extranet-widget--list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
      }
    }
  }

  &[data-width='4'],
  &[data-width='6'],
  &[data-width='7'],
  &[data-width='8'],
  &[data-width='9'] {
    .extranet-widget--list-item {
      display: flex;
      align-items: center;
    }
    .extranet-widget--list-item-image {
      flex-basis: 64px;
      flex-grow: 0;
      flex-shrink: 0;
    }
    .extranet-widget--list-item-title {
      @include textrow(1);
    }
  }
  &[data-width='6'],
  &[data-width='7'],
  &[data-width='8'],
  &[data-width='9'] {
    .extranet-widget--list {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .extranet-widget--list-item {
      display: flex;
      align-items: center;
    }
    .extranet-widget--list-item-image {
      flex-basis: 64px;
      flex-grow: 0;
      flex-shrink: 0;
    }
    .extranet-widget--list-item-title {
      @include textrow(1);
    }
    &[data-height='2'],
    &[data-height='3'],
    &[data-height='4'] {
      .extranet-widget--list-item:nth-child(n + 3) {
        display: none;
      }
    }
    &[data-height='5'] {
      .extranet-widget--list-item:nth-child(n + 5) {
        display: none;
      }
    }
    &[data-height='6'] {
      .extranet-widget--list-item:nth-child(n + 7) {
        display: none;
      }
    }
  }
  /* END WIDGET RESIZE */

  /* NO DATA */
  &--information {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    p {
      font-weight: bold;
      font-size: 1.8rem;
    }
    img {
      width: 80%;
    }
  }
  /* START WIDGET RESIZE */
  &[data-width='3'] {
    &[data-height='2'],
    &[data-height='3'],
    &[data-height='4'] {
      .extranet-widget--information {
        p {
          font-size: 1.4rem;
        }
        img {
          width: 50%;
        }
      }
    }
    &[data-height='5'] {
      .extranet-widget--information {
        p {
          font-size: 1.6rem;
        }
        img {
          width: 70%;
        }
      }
    }
    &[data-height='6'] {
      .extranet-widget--information {
        img {
          width: 100%;
        }
      }
    }
  }
  &[data-width='4'],
  &[data-width='5'],
  &[data-width='6'],
  &[data-width='7'],
  &[data-width='8'],
  &[data-width='9'],
  &[data-width='10'],
  &[data-width='11'],
  &[data-width='12'] {
    .extranet-widget--information {
      flex-direction: row;
      height: calc(100% - 120px);
      p {
        flex: auto;
      }
    }
    &[data-height='2'],
    &[data-height='3'],
    &[data-height='4'] {
      .extranet-widget--information {
        p {
          font-size: 1.4rem;
        }
        img {
          object-fit: contain;
          max-height: 100%;
        }
      }
    }
    &[data-height='5'] {
      .extranet-widget--information {
        p {
          font-size: 1.6rem;
        }
        img {
          object-fit: contain;
          max-height: 100%;
        }
      }
    }
    &[data-height='6'] {
      .extranet-widget--information {
        img {
          object-fit: contain;
          max-height: 100%;
        }
      }
    }
  }
  /* END WIDGET RESIZE */

  /* START RESPONSIVE */
  /* @include media(XL) {
    &--list {
      margin-top: 32px;
    }
    &--list-item-title {
      font-size: 1.3rem;
      line-height: 140%;
    }
    &--list-item-date-category {
      flex-direction: column;
      gap: 0;
    }
    &[data-width='3'] {
      .extranet-widget--list-wrapper {
        padding: 1.6rem;
      }
    }
    &[data-width='3'][data-height='3'] {
      gap: 1rem;
    }
    &[data-width='3'][data-height='4'] {
      gap: 1.8rem;
    }
    &[data-width='3'][data-height='5'] {
      gap: 2.7rem;
    }
    &[data-width='3'][data-height='6'] {
      gap: 2.7rem;
    }
    &[data-width='5'],
    &[data-width='6'] {
      &[data-height='3'],
      &[data-height='4'],
      &[data-height='5'],
      &[data-height='6'] {
        .extranet-widget--list {
          display: flex;
        }
        .extranet-widget--list-item {
          display: flex;
        }
      }
      &[data-height='3'] {
        .extranet-widget--list-item:nth-child(n + 2) {
          display: none;
        }
      }
      &[data-height='4'] {
        .extranet-widget--list-item:nth-child(n + 3) {
          display: none;
        }
      }
      &[data-height='5'] {
        .extranet-widget--list-item:nth-child(n + 4) {
          display: none;
        }
      }
    }

    &[data-width='4'] {
      .extranet-widget--information {
        flex-direction: column;
      }
      &[data-height='3'] {
        .extranet-widget--information img {
          height: 100px;
          width: auto;
        }
      }
      &[data-height='4'] {
        .extranet-widget--information img {
          height: 210px;
          width: auto;
        }
      }
      &[data-height='5'] {
        .extranet-widget--information img {
          height: auto;
          width: 90%;
        }
      }
      &[data-height='6'] {
        .extranet-widget--information img {
          height: auto;
          width: 90%;
        }
      }
    }
  }
  @include media(LG) {
    &--list {
    }
    &--list-item {
    }
    &--list-item-title {
    }
    &--list-item-date-category {
    }

    &[data-width='4'],
    &[data-width='5'],
    &[data-width='6'],
    &[data-width='7'],
    &[data-width='8'] {
      .extranet-widget--list-item {
        display: grid;
      }
      &[data-height='3'] {
        .extranet-widget--list {
          display: flex;
        }
        .extranet-widget--list-item:nth-child(n + 2) {
          display: none;
        }
      }
      &[data-height='4'] {
        .extranet-widget--list {
          display: flex;
        }
        .extranet-widget--list-item:nth-child(n + 3) {
          display: none;
        }
      }
      &[data-height='5'] {
        .extranet-widget--list {
          display: flex;
        }
        .extranet-widget--list-item:nth-child(n + 4) {
          display: none;
        }
      }
      &[data-height='6'] {
        .extranet-widget--list {
          display: flex;
        }
        .extranet-widget--list-item:nth-child(n + 4) {
          display: none;
        }
      }
    }

    &[data-width='4'],
    &[data-width='5'],
    &[data-width='6'] {
      .extranet-widget--information {
        flex-direction: column;
      }
      &[data-height='3'] {
        .extranet-widget--information {
          p {
            font-size: 1.3rem;
          }
        }
      }
      &[data-height='4'] {
        .extranet-widget--information {
          img {
            width: 60%;
          }
        }
      }
      &[data-height='5'] {
        .extranet-widget--information {
          img {
            width: 100%;
          }
        }
      }
      &[data-height='6'] {
        .extranet-widget--information {
          img {
            width: 100%;
          }
        }
      }
    }

    &[data-width='4'] {
      .extranet-widget--information {
      }
      .extranet-widget--list-wrapper {
        padding: 1rem;
      }
      .extranet-widget--list {
        margin-top: 1rem;
      }
      .extranet-widget--list-item {
        display: grid;
      }
      .extranet-widget--list-item-title {
        @include textrow(2);
      }
      .extranet-widget--list-item-date-category {
        flex-direction: column;
        gap: 4px;
      }
    }

    &[data-width='12'] {
      .extranet-widget--list {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
      .extranet-widget--information {
      }
      .extranet-widget--list-item {
        display: flex;
        align-items: center;
      }
      .extranet-widget--list-item-image {
        flex-basis: 64px;
        flex-grow: 0;
        flex-shrink: 0;
      }
      .extranet-widget--list-item-title {
        @include textrow(1);
        height: 1em;
      }
      &[data-height='3'] {
        .extranet-widget--list-item {
          display: flex;
        }
        .extranet-widget--list-item:nth-child(n + 3) {
          display: none;
        }
      }
      &[data-height='4'] {
        .extranet-widget--list-item {
          display: flex;
        }
        .extranet-widget--list-item:nth-child(n + 5) {
          display: none;
        }
      }
      &[data-height='5'] {
        .extranet-widget--list-item {
          display: flex;
        }
      }
      &[data-height='6'] {
        .extranet-widget--list-item {
          display: flex;
        }
      }
    }
  } */
}
