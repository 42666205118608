/** @format */

@font-face {
  font-family: 'ContinentalStagSansW';
  src: url('../../assets/font/contiFonts/Continental_Stag_Sans_Book.otf') format('opentype');
}
@font-face {
  font-family: 'ContinentalStagSansW-M';
  src: url('../../assets/font/contiFonts/ContinentalStagSans-Med.otf') format('opentype');
}
@font-face {
  font-family: 'ContinentalStagSansW-L';
  src: url('../../assets/font/contiFonts/ContinentalStagSans-Light.otf') format('opentype');
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
  font-size: 62.5%;
  margin: 0 auto;
  overflow-x: hidden;
  font-family: 'ContinentalStagSansW', 'Noto Sans';
  background-color: $body-bg;
}

// img {
//   width: 100%;
// }

// a {
//   text-decoration: none !important;
//   color: inherit !important;
//   display: flex;
//   align-items: center;
//   font-style: normal !important;
// }

// a:hover {
//   text-decoration: none !important;
//   color: $blue !important;
//   // color: $primary-orange !important;
// }

// a:visited,
// a:focus {
//   text-decoration: none !important;
//   color: inherit !important;
// }
