/** @format */

.shop-widget {
  height: 100%;
  background-color: $bgWidget;
  padding: 2.4rem;
  border-radius: 1.6rem;
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: 3.2rem;
  overflow: hidden;
  transition: 0.3s ease all;
  &--title {
    h2 {
      font-size: 3rem;
    }
  }
  &--link {
    margin-top: auto;
    font-weight: bold;
    background-image: url($arrow-right);
    background-repeat: no-repeat;
    background-position: left -4px;
    padding-left: 3rem;
  }
  &--list {
    font-size: 1.8rem;
    position: relative;
  }
  &--list-item {
    flex: 1;
    background-color: #faf4eb;
    padding: 1.6rem;
    border-radius: 0.8rem;
  }
  &--list-item-title {
    margin-top: 1.4rem;
    font-weight: bold;
    @include textrow(1);
  }
  &--list-item-image {
    position: relative;
  }
  &--list-item-category {
    background-color: #fea500;
    position: absolute;
    top: 1.1rem;
    left: 1.1rem;
    font-size: 1.3rem;
    color: black;
    padding: 4px 8px;
  }
  &--list-item-image img {
    display: block;
    object-fit: cover;
    border-radius: 0.4rem;
    transition: 0.3s ease all;
    width: 100%;
    height: 190px;
  }
  &--list-item-price {
    margin-top: 1.6rem;
    font-size: 1.3rem;
    color: #6e6e6e;
  }
  &--list-item-priceSale {
    font-size: 1.3rem;
    font-weight: bold;
    margin-top: 0.4rem;
  }
  &--list-empty {
    font-weight: bold;
  }

  .slick-slider.slick-initialized {
    .slick-slide {
      padding: 0 0.8rem;
    }
    .slick-arrow {
      width: 25px;
      height: 25px;
      top: calc(100% + 16px);
      transform: none;
      z-index: 2;
      &:before {
        display: none;
      }
      &.slick-prev {
        left: 0px;
        background: url('data:image/svg+xml,<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_2729_16344)"><path d="M20 11.1357H7.83L13.42 5.54574L12 4.13574L4 12.1357L12 20.1357L13.41 18.7257L7.83 13.1357H20V11.1357Z" fill="black"/></g><defs><clipPath id="clip0_2729_16344"><rect width="24" height="24" fill="white" transform="translate(0 0.135742)"/></clipPath></defs></svg>')
          center no-repeat;
      }
      &.slick-next {
        right: 0px;
        background: url('data:image/svg+xml,<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_2729_16358)"><path d="M12 4.13574L10.59 5.54574L16.17 11.1357H4V13.1357H16.17L10.59 18.7257L12 20.1357L20 12.1357L12 4.13574Z" fill="black"/></g><defs><clipPath id="clip0_2729_16358"><rect width="24" height="24" fill="white" transform="translate(0 0.135742)"/></clipPath></defs></svg>')
          center no-repeat;
      }
    }
    .slick-dots {
      top: calc(100% + 11px);
      bottom: auto;
      display: flex !important;
      gap: 8px;
      justify-content: center;
      align-items: center;
      li {
        width: 16px;
        margin: 0;
        button {
          width: auto;
          &:before {
            content: '';
            width: 16px;
            height: 2px;
            background-color: #fea500;
            opacity: 0.5;
            top: 50%;
          }
        }
      }
      li.slick-active {
        width: 32px;
      }
      li.slick-active button:before {
        width: 32px;
        background-color: #fea500;
        opacity: 1;
      }
    }
  }

  &--order-title,
  &--order-item {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    padding: 1.6rem 0;
    a {
      display: inline-block;
      border-bottom: 1px solid black;
    }
    .shop-widget--order-item-number {
    }
    .shop-widget--order-item-date {
    }
    .shop-widget--order-item-price {
    }
    .shop-widget--order-item-status {
      span {
        display: inline-block;
        font-size: 1.3rem;
        padding: 4px 8px;
        text-transform: capitalize;
      }
      .inprogress {
        background-color: #fea500;
      }
      .onhold {
        background-color: #fde1e4;
      }
      .submitted {
        background-color: #f4cf9b;
      }
    }
    .shop-widget--order-item-action {
    }
  }
  &--order-title {
    font-weight: bold;
  }
  &--order-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }

  &[data-width='3'] {
    &[data-height='5'] {
      .shop-widget--list-item-image img {
        height: 150px;
      }
    }
    &[data-height='6'] {
      .shop-widget--list-item-image img {
        height: 260px;
      }
    }
  }
  &[data-height='6'] {
    .shop-widget--list-item-image img {
      height: 290px;
    }
  }

  &[data-width='3'] {
    .shop-widget--order-title,
    .shop-widget--order-item {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .shop-widget--order-item-date,
    .shop-widget--order-item-price {
      display: none;
    }
  }
  &[data-width='4'],
  &[data-width='5'] {
    .shop-widget--order-title,
    .shop-widget--order-item {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .shop-widget--order-item-date {
      display: none;
    }
  }

  /* < 1920 */
  @include media(XL) {
    &[data-width='3'] {
      .shop-widget--order-title,
      .shop-widget--order-item {
        grid-template-columns: 1fr 1fr;
      }
      .shop-widget--order-item-date,
      .shop-widget--order-item-price,
      .shop-widget--order-item-action {
        display: none;
      }
    }
    &[data-width='4'],
    &[data-width='5'] {
      .shop-widget--order-title,
      .shop-widget--order-item {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
      .shop-widget--order-item-date {
        display: none;
      }
    }
  }
  /* < 1440 */
  @include media(LG) {
    &[data-width='3'] {
      .shop-widget--order-title,
      .shop-widget--order-item {
        grid-template-columns: 1fr 1fr;
      }
      .shop-widget--order-item-date,
      .shop-widget--order-item-price,
      .shop-widget--order-item-action {
        display: none;
      }
    }
    &[data-width='4'],
    &[data-width='5'] {
      .shop-widget--order-title,
      .shop-widget--order-item {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
      .shop-widget--order-item-date {
        display: none;
      }
    }
  }
}
