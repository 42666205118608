/** @format */

.card-multi-link {
  /* background: linear-gradient(114.34deg, #196b96 0%, #2e90ac 98.73%); */
  background-color: #373737;
  padding: 2.4rem;
  border-radius: 16px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    top: 2.4rem;
    left: 2.4rem;
    width: 5.4rem;
    height: 5.4rem;
    background: #08c476;
    mix-blend-mode: darken;
  }
  img {
    width: 38px;
    height: 54px;
  }

  h2.card-title {
    text-transform: uppercase;
    color: #fff;
    font-weight: 700;
    margin-bottom: 20px;
    width: 100%;
    padding-right: 50px;
    font-size: 1.8rem;
    @include media(sx) {
      font-size: 1.8rem;
      padding-right: 0;
    }
    @include media(xlg) {
      font-size: 2.4rem;
    }
  }
  a p {
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.2rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    margin-bottom: 1.4rem;
  }
  a.main-link p {
    font-size: 1.4rem;
    border-bottom: 0;
    position: relative;
    padding-left: 16px;
    &:before {
      content: '';
      display: block;
      position: absolute;
      border: 8px solid #fff;
      width: 0;
      height: 0;
      top: 0;
      left: 0;
      border-color: transparent transparent transparent #fff;
    }
  }
  .card-flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    h4 {
      color: #fff;
      border-bottom: 1px solid white;
      font-size: 13px;
      padding-bottom: 5px;
      margin-bottom: 10px;
    }
    .others-link {
      a {
        position: relative;
        display: block;
        &:before {
          content: '';
          display: block;
          position: absolute;
          border: 8px solid #fff;
          width: 0;
          height: 0;
          top: 0;
          left: 0;
          border-color: transparent transparent transparent #fff;
        }
        p {
          padding-right: 32px;
          padding-left: 16px;
          border: 0;
          font-size: 14px;
        }
      }
    }
  }
}
