/** @format */

header {
  width: 100%;
  height: $header-height;
  background-color: $white;
  border-bottom: 1px solid #d6d6d6;
  // position: fixed;
  // top: 68px;
  // z-index: 1049;
  @media screen and (max-width: 991px) {
    height: 85px;
  }
  @media screen and (max-width: 768px) {
    top: 48px;
  }

  .container {
    @include flex;
    align-items: center;
    width: initial;
    height: 100%;

    // .apps-button {
    //   cursor: pointer;
    // }

    .main-logo {
      .link-box {
        position: relative;
        top: 13px;
        @media screen and (max-width: 991px) {
          svg {
            height: auto;
            width: 180px;
          }
        }
      }
    }
    //   width: 12.4rem;
    //   margin: 0 1rem;
    //   transition: 0.3s ease;
    //   @include media(sx) {
    //     margin-right: 2.4rem;
    //   }
    //   img {
    //     min-width: 6rem;
    //   }

    //   @include media(sx) {
    //     width: 12.4rem;
    //     margin-right: 2.4rem;
    //     img {
    //       width: 12.4rem;
    //     }
    //   }
    //   @include media(xl) {
    //     width: 15.4rem;
    //   }
    //   img {
    //     width: 100%;
    //   }
    // } // end main-logo

    .user-box {
      margin-left: auto;
      .user-info__detail {
        text-align: right;
        color: $black;
        .name {
          font: 500 14px/15px ContinentalStagSansW-M;
          font-family: 'ContinentalStagSansW-M', 'Noto Sans';
          margin-bottom: 4px;
        }
        .title {
          font: 300 14px/15px ContinentalStagSansW-L;
          font-family: 'ContinentalStagSansW-L', 'Noto Sans';
        }
      }
      .user-info {
        @include flex;
        visibility: hidden;
        width: 0;
        height: 0;
        align-items: center;
        column-gap: 0.8rem;
        @include media(xl) {
          width: 100%;
          height: auto;
          visibility: visible;
        }
      }
    } // end user-box
  }
}

.logo-title {
  margin-left: 63px;
  font: 27px/28px 'ContinentalStagSansW';
  font-family: 'ContinentalStagSansW', 'Noto Sans';
  @media screen and (max-width: 991px) {
    margin-left: 35px;
  }
}
// .site-list {
//   font-family: "Etelka", sans-serif;

//   .active-site {
//     font-family: "arial";
//     display: inline-block;
//     width: 100%;
//     max-width: 6.3rem;
//     font-size: 1.3rem;
//     font-weight: 400;
//     color: rgba(115, 115, 115, 1);

//     @include media(xl) {
//       font-size: 1.9rem;
//       line-height: 2.2rem;
//       max-width: 9.3rem;
//     }
//   }
// }

.site-list_dropdown .ant-dropdown-menu {
  min-width: 25rem;
  font-family: inherit;
  .ant-dropdown-menu-title-content {
    font-family: 'Etelka', sans-serif;
  }
}
// user-info dropdrow
.user-info_dropdown ul.ant-menu {
  list-style: none;
  background-color: $dropdown-bg;
  padding: 2rem;
  margin-top: 2rem;
  // font-family: "Etelka", sans-serif;
  font-family: ContinentalStagSansW;
  font-family: 'ContinentalStagSansW', 'Noto Sans';
  a {
    text-decoration: none;
    font-size: 1.8rem;
    line-height: 3.2rem;
    // color: $blue;
    color: $black;
    &:hover {
      // color: $dark-font;
      color: $primary-orange;
      // font-style: italic;
    }
  }
}

.arrow-triangle.ant-dropdown-open::before {
  transform: rotate(180deg);
}
.hamberger-nav {
  margin-left: 1rem;
  cursor: pointer;
  width: 3rem;
  height: 2rem;
  display: block;
  position: relative;
  backface-visibility: hidden;

  @include media(sx) {
    width: 3.6rem;
    height: 2.4rem;
  }
  @include media(xl) {
    display: none;
  }

  i {
    position: absolute;
    display: block;
    height: 4px;
    width: 100%;
    // background-color: $blue;
    background-color: $black;
    transition: 0.2s ease;
  }

  i:nth-child(1) {
    top: 0;
  }
  i:nth-child(2) {
    top: 50%;
    transform: translateY(-50%);
  }
  i:nth-child(3) {
    bottom: 0;
  }

  &.ant-dropdown-open {
    i:nth-child(1) {
      top: 0.6rem;
      @include media(sx) {
        top: 1rem;
      }
      transform: rotate(135deg);
    }
    i:nth-child(2) {
      opacity: 0;
    }
    i:nth-child(3) {
      bottom: 1rem;
      transform: rotate(-135deg);
    }
  }
}

.language-button {
  border-radius: 8px;
  box-sizing: border-box;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d6d6d6;
  color: $black;
  cursor: pointer;
  .language-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .language-title {
      font-family: 'Noto Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 8px;
    }

    .language-market {
      font-family: 'Noto Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.language-button-selected {
  border: 1px solid $black;
}

.language-selector-body {
  margin-bottom: 24px;
  color: $black;

  .language-selector-title {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 16px;
  }

  .language-selector-sub-title {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 24px;
  }
}
