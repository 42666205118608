/** @format */

.headerWrapper {
  height: 68px;
  position: fixed;
  background: #f7f7f7;
  top: 0;
  z-index: 1049;
  display: flex;
  align-items: center;
  border-bottom: 8px solid #ffffff;
  max-width: none;
  width: 100%;
  z-index: 1049;

  @media (max-width: 768px) {
    height: 48px;
  }

  .apps-icon {
    cursor: pointer;
    &.ant-dropdown-open {
      svg {
        path {
          fill: #fea500;
          transition: all 0.5s ease;
        }
      }
    }
  }

  [class*='container'] {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.menuDropDown {
  padding: 2rem;
  margin-top: 20px;
  background-color: #fff;
  list-style: none;
  line-height: 2.5;
  width: 240px;
  border: 1px solid #d6d6d6;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  @media (max-width: 576px) {
    width: calc(100vw - 40px);
  }

  a {
    color: #333333;
    &:hover {
      color: #234d96;
    }
  }

  &--user {
    li > span > a {
      display: flex;
      align-items: center;
      gap: 10px;

      img {
        height: 20px;
        width: auto;
      }
    }
  }
}

.userBox {
  font-size: inherit;
}

.dropDown {
  & > ul {
    background-color: #fff;
    line-height: 2.5;
    width: 240px;
    border: 1px solid #d6d6d6;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-top: 20px;

    @media (max-width: 576px) {
      width: calc(100vw - 40px);
    }

    & > li {
      & > span {
        & > a {
          display: flex;
          align-items: center;
          gap: 10px;
          color: #333333;
          font-size: 18px;

          @media (max-width: 768px) {
            font-size: 14px;
          }
          &:hover {
            color: #234d96;
          }
          & > img {
            width: 20px;
            height: auto;
            display: block;
            position: relative;
            top: -2px;
          }
        }
      }
    }
  }
}

.userInfo {
  display: flex;
  align-items: center;
  gap: 15px;
  &Detail {
    &__name {
      color: #234d96;
      font-size: 14px;
      border-bottom: 1px solid #234d96;
    }
  }
  &DropDown {
    font-size: 14px;
    &Button {
      padding: 0;
      border: 0;
    }
    @extend .dropDown;
  }
}
.avatarBox {
  width: 36px;
  height: 36px;

  img {
    width: 36px;
    height: 36px;
    border-radius: 36px;
  }
  @media (max-width: 768px) {
    width: 24px;
    height: 24px;
  }

  &__firstLetter {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    background: linear-gradient(25.53deg, #fea500 0%, #ffca79 100%);
    font-size: 20px;
    font-weight: normal;
  }
}

.separate {
  border-top: 1px solid #d6d6d6;
  display: block;
  height: 0;
  width: calc(100% + 40px);
  margin: 15px -20px;
}
