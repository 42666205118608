/** @format */

.tbl {
  display: block;
  border: 1px solid rgba(0, 0, 0, 0.16);

  .tbl-row {
    display: flex;
    flex-wrap: wrap;

    .tbl-col {
      padding: 15px;
      flex: 0 0 33%;
      max-width: 33%;
    }

    .tbl-col + .tbl-col {
      border-left: 1px solid rgba(0, 0, 0, 0.16);
    }
  }

  .tbl-row + .tbl-row {
    border-top: 1px solid rgba(0, 0, 0, 0.16);
  }
}

.googleAnalytics {
  display: flex;

  .switch_content {
    flex: 0 0 90%;
    max-width: 90%;
  }

  .switch_btn {
    flex: 0 0 10%;
    max-width: 10%;
    text-align: right;
  }
}

.tutorial-container {
  iframe {
    margin: 10px 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #234d96;
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 10px;
  }
}
