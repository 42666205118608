/** @format */

button {
  cursor: pointer;
  padding: 1rem 4rem;
  @include media(md) {
    padding: 0 4rem;
  }
  border: 1px solid $dark-font;
  letter-spacing: 0.22px;
  border-radius: 3rem;
  background-color: transparent;
  text-transform: uppercase;
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 2.64rem;
  transition: 0.2s ease-in;
}
.button {
  cursor: pointer;
  padding: 1rem 4rem;
  @include media(md) {
    padding: 0 4rem;
  }
  border: 1px solid $dark-font;
  letter-spacing: 0.22px;
  border-radius: 3rem;
  background-color: transparent;
  text-transform: uppercase;
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 2.64rem;
  transition: 0.2s ease-in;
}

.btn-trans {
  //margin-right: 1.5rem;
  border: none;
  // font-size: 1.7rem;
  font: 500 16px/26px ContinentalStagSansW-M;
  font-family: 'ContinentalStagSansW-M', 'Noto Sans';
  padding: 8px 32px;

  // @include media(xs) {
  //   padding: 3rem 0rem;
  // }
}

// .vdo-blue {
//   color: #234d96;
// }

.vdo-dark-grey-2 {
  color: #868686;
}

.delete-button {
  text-align: left;
  padding: 1rem;
  @include media(md) {
    padding: 0rem 3rem;
  }
  // @include media(sx) {
  //   padding: 1;
  // }
}

// .black-bordered {
//   margin-left: 1.5rem;
//   border-radius: 25px;
//   font-size: 1.7rem;
//   padding: 1rem 1.5rem;
//   color: #333;
//   font-weight: 700;
//   &:hover {
//     background: transparent;
//   }
// }

// .vdo-dark-grey-1 {
//   color: #333333;
// }

.btn-icon__green-triangle::before {
  height: 3rem;
  width: 1.5rem;
  @include icon($green-triangle);
  display: none;
  vertical-align: middle;
  margin-right: 0.7rem;
  @include media(sx) {
    display: inline-block;
  }
}

button.primary {
  border: 1px solid $dark-font;
  color: $dark-font;
  &:hover {
    color: $white;
    background-color: $green;
    border-color: $green;
    &.btn-icon__green-triangle::before {
      @include icon($white-triangle);
      display: inline-block;
    }
  }
  &:focus {
    color: $white;
    background-color: $green;
    border: 2px solid #f2a50e;
    &.btn-icon__green-triangle::before {
      @include icon($white-triangle);
      display: inline-block;
    }
  }
  &:active {
    transform: scale(0.95);
    transition: 0.2s ease-in-out;
  }
}

button.thick-border {
  border: 2px solid $dark-font;
}
.thick-border {
  border: 2px solid $dark-font;
}

.btn-login {
  padding: 0.5rem 1.5rem;
  @include media(sx) {
    padding: 1rem 4rem;
  }
  @include media(md) {
    padding: 0rem 4rem;
    width: 23.2rem;
    height: 4.9rem;
  }
}

.back-btn {
  background-color: $primary-orange;
  color: $black;
  border-radius: 30px;
  border: none;
  outline: none;
  padding: 20px 30px;
  font: 500 16px/16px ContinentalStagSansW-M;
  font-family: 'ContinentalStagSansW-M', 'Noto Sans';
  text-transform: initial;
}

.back-btn-blue {
  background-color: #234d96;
  color: #fff !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  border: none;
  padding: 2rem;
  font-size: 1.4rem;
  font-weight: 400;
  @include media(md) {
    padding: 2rem 4rem;
    font-size: 1.4rem;
  }
}

.call-btn-blue {
  img {
    width: 20px;
    margin-right: 20px;
  }
  margin-bottom: 2rem;
  @extend .back-btn-blue;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @include media(md) {
    font-size: 1.8rem;
    letter-spacing: 0.8px;
    font-weight: 400;
  }
}
.btn-container {
  margin: 30px 0;
}

/* new Buttom implement 2024 */
.primary__green-triangle {
  padding: 0 3.2rem 0 2.4rem;
  height: 60px;
  font-size: 2rem;
  text-transform: unset;
  display: flex;
  align-items: center;
  border: 1px solid $dark-font;
  border-radius: 100px;
  transition: 0.2s ease-in;
  background-color: $primary-orange;
  border-color: $primary-orange;
  color: $black;
  font-weight: 500;
  width: fit-content;
  cursor: pointer;
  &:before {
    transition: 0.2s ease-in;
    height: 3.2rem;
    width: 3.2rem;
    @include icon($arrow-right);
    margin-right: 1rem;
  }

  &.secondary {
    height: 48px;
    font-size: 1.6rem;
  }
  &.border-only {
    background: transparent;
    border-color: $black;
    color: $black;
    &:before {
      @include icon($arrow-right);
    }

    &:disabled {
      background: transparent !important;
    }
  }
  &.no-arrow {
    padding: 0 2.4rem;
    &:before {
      display: none !important;
    }
  }
  &.tertiary {
    padding: 0 2.4rem;
    height: 32px;
    font-size: 1.6rem;
    &:before {
      display: none !important;
    }
  }

  &:focus {
    background: $primary-orange;
    border-color: $primary-orange;
    color: $black;
    &:before {
      @include icon($arrow-right);
    }
  }
  &:hover {
    background: $primary-orange !important;
    border-color: $primary-orange;
    color: $black;
    &:before {
      @include icon($arrow-right);
    }
  }
  &:active {
    background: #108857 !important;
    border-color: #108857 !important;
    color: $white;
    &:before {
      @include icon($arrow-right);
    }
  }
  &:disabled {
    background: #cdcdcd !important;
    border-color: #cdcdcd !important;
    color: #9c9d9d !important;
    cursor: not-allowed !important;
    &:before {
      opacity: 0.3;
      @include icon($arrow-right);
    }
  }

  &.check {
    &:before {
      @include icon($black-check);
      width: 18px;
      height: 14px;
    }
    &:focus {
      &:before {
        @include icon($black-check);
      }
    }
    &:hover {
      &:before {
        @include icon($black-check);
      }
    }
    &:active {
      &:before {
        @include icon($white-check);
      }
    }
    &:disabled {
      &:before {
        opacity: 0.3;
        @include icon($gray-check);
      }
    }
  }
  &.back {
    &:before {
      width: 24px;
      height: 24px;
      @include icon($back-arrow);
      background-size: 24px 24px;
    }
    &:focus {
      &:before {
        /* @include icon($black-triangle-back); */
      }
    }
    &:hover {
      &:before {
        /* @include icon($black-triangle-back); */
      }
    }
    &:active {
      &:before {
        @include icon($back-arrow-white);
      }
    }
    &:disabled {
      &:before {
        @include icon($back-arrow-gray);
      }
    }
  }
  &.ghost {
    background: transparent;
    border-color: transparent;
    font-size: 1.6rem;
    height: 40px;
    padding: 0 2.4rem;
    color: $black;
    &:before {
      @include icon($arrow-right);
      order: 2;
      margin-right: 0;
      margin-left: 1.6rem;
    }

    &:focus {
      background: transparent !important;
      border: 1px dashed $primary-orange !important;
      color: $black;
      &:before {
        @include icon($arrow-right);
      }
    }
    &:hover {
      text-decoration: underline;
      background: transparent !important;
      border-color: transparent !important;
      color: $primary-orange;
      &:before {
        @include icon($arrow-right);
      }
    }
    &:active {
      text-decoration: underline;
      background: transparent !important;
      border-color: transparent !important;
      color: $primary-orange;
      &:before {
        @include icon($arrow-right);
      }
    }
    &:disabled {
      text-decoration: none !important;
      background: transparent !important;
      border-color: transparent !important;
      color: #9c9d9d;
      &:before {
        opacity: 0.3;
        @include icon($arrow-right);
      }
    }
  }
}
