/** @format */

footer {
  @include flex;
  align-items: center;
  // margin-top: auto;
  // background-color: $dark-font;
  background: $color-light-gray;
  color: $black;
  @include media(md) {
    height: 8.1rem;
  }
  .container {
    @include media(xxl) {
      padding: 0 26rem;
    }
  }
  .footer-inner {
    width: 100%;
    @include flex;
    justify-content: space-between;
    align-items: center;
    font: 16px/17px ContinentalStagSansW-M;
    font-family: 'ContinentalStagSansW-M', 'Noto Sans';
    @media (max-width: 767px) {
      padding: 3rem 0;
      flex-direction: column;
      align-items: flex-start;
    }
    // .footer-copyright {
    //   color: $white;
    // }
    .footer-link {
      list-style: none;
      @include flex;
      column-gap: 32px;
      // color: $footer-link;
      // li {
      //   display: inline-block;
      //   // &:hover {
      //   //   color: $white;
      //   // }
      // }
      // li:not(:first-child) {
      //   margin-left: 3rem;
      // }
      @media (max-width: 767px) {
        margin-top: 4rem;
        flex-direction: column;
        li {
          display: block;
        }
        li:not(:first-child) {
          margin-left: 0;
          margin-top: 3rem;
        }
      }
    }
  }
}
