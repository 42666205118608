/** @format */

.saved-item-box {
  border-radius: 8px;
  box-sizing: border-box;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d6d6d6;
  color: $black;
  cursor: pointer;
  .saved-item-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .saved-item-title {
      font-family: 'Noto Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
    }

    .saved-item-sub-title {
      font-family: 'Noto Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
  }
  .saved-item-time-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #8d8d8d;
  }

  .dot {
    color: #c60000;
    margin-right: 0.5rem;
  }
}
