/** @format */

.partnerFinder-widget {
  height: 100%;
  background-color: $bgWidget;
  padding: 2.4rem;
  border-radius: 1.6rem;
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: 3.2rem;
  overflow: hidden;
  transition: 0.3s ease all;
  &--title {
    h2 {
      font-size: 3rem;
    }
  }
  &--search {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.8rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
    padding-bottom: 24px;
    input {
      border: none;
      border-bottom: 1px solid black;
      height: 30px;
      width: 100%;
      font-size: 1.8rem;
      background-color: transparent;
      background-image: url($search);
      background-repeat: no-repeat;
      background-position: right center;
      &:focus,
      &:focus-visible {
        border: none;
        border-bottom: 1px solid black;
        outline: none;
      }
    }
    strong {
      font-size: 1.3rem;
    }
    .partnerFinder-widget--detect-automation {
      display: inline-block;
      padding-right: 34px;
      font-size: 1.6rem;
      background-image: url($location-icon);
      background-repeat: no-repeat;
      background-position: right center;
      text-decoration: underline !important;
      font-weight: bold;
    }
  }
  &--list-wrapper {
    padding-bottom: 2.4rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  }
  &--list-title {
    font-size: 1.8rem;
  }
  &--list {
    margin-top: 1.6rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.8rem;
  }
  &--list-item {
    font-size: 1.5rem;
    padding: 0.8rem;
    background-color: #f4e7d4;
    font-weight: bold;
    &:first-child {
      background-color: #fea500;
    }
  }
  &--link {
    margin-top: auto;
    font-weight: bold;
    background-image: url($arrow-right);
    background-repeat: no-repeat;
    background-position: left -4px;
    padding-left: 3rem;
  }
}
