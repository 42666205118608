/** @format */
$bottom-height: 13.6rem;

.landing-login {
  background-color: $white;
  position: relative;
  width: 100%;
  min-height: 100vh;
  @include flex;
  .left-wrapper {
    position: relative;
    @include flex;
    flex-direction: column;
    width: 100%;
    @include media(md) {
      max-width: 48rem;
    }

    .inner-content-box {
      padding: 2rem;
      text-align: center;
      flex: 1;
      @include media(md) {
        padding: 9rem;
      }
      .content-wrapper {
        @include flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        button {
          width: 100%;
          max-width: 29.7rem;
          height: 6.4rem;
          @include center;
        }
        h2 {
          font-size: 3rem;
          color: $blue;
          font-weight: 400;
          line-height: 36px;
        }
        p {
          margin-top: 2rem;
          margin-bottom: 2rem;
          font-size: 1.4rem;
          line-height: 16px;
          letter-spacing: 0.14px;
        }
        a.link {
          display: inline-block;
          margin-top: 4rem;
          font-size: 1.4rem;
          line-height: 16px;
          color: $blue;

          &:hover {
            opacity: 0.8;
          }
        }
      }
    } // end inner content
    .landing-footer {
      @include flex;
      flex-direction: column;
      font-size: 1.4rem;
      background-color: $dark-font;
      color: $white;
      padding: 3.5rem 3rem;
      height: auto;
      justify-content: space-between;
      align-items: center;
      @include media(md) {
        align-items: flex-start;
        height: $bottom-height;
      }
      ul {
        list-style: none;
        text-align: center;
        @include media(md) {
          text-align: left;
        }
        li {
          margin-bottom: 2rem;
          display: inline-block;
          &:not(:last-child) {
            margin-right: 1.7rem;
          }
        }
      }
      .buildVersion{
        display: block;
        width: 100%;
        .login_tbl{
          display: flex;
          flex-wrap: wrap;
        .copy{flex: 0 0 40%; max-width: 40%;}
        .buildVersion_login{text-align: right; flex: 0 0 60%; max-width: 60%;}
        }
        
      }
    }
    // end footer
  }
  //end left
  .right-wrapper {
    display: none;
    @include media(md) {
      flex: 1;
      @include flex;
      flex-direction: column;
    }
    .bg-img {
      background-image: url('../../assets/imgs/bg-login.jpg');
      background-position: top;
      background-size: cover;
      background-repeat: no-repeat;
      flex: 1;
    }
    .bg-logo {
      height: $bottom-height;
      @include flex;
      justify-content: flex-end;
      align-items: center;
      img {
        max-width: 40rem;
        margin-left: 2rem;
        margin-right: 2.4rem;
      }
      h2 {
        font-size: 4.2rem;
        color: rgba(146, 146, 146, 1);
        display: none;
        max-width: 23rem;
        @include media(lg) {
          display: inline-block;
        }
      }
    }
  }
  
}
