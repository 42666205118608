/** @format */
.langselect {
  font-family: 'Noto Sans', sans-serif;
  float: right;
  position: relative;
  display: block;
  font-size: 17px;
  color: #333;
  line-height: 1.3;
  padding: 0.2rem 1rem;
  width: 100%;
  max-width: 200px;
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: 0.25em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  background-color: #ffffff;
  background-image: url('../../assets/imgs/chevron-down.svg');
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right 0.5em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: 0.7em auto, 100%;
  border: none;
  option {
    font-weight: normal;
    color: #000;
    font-size: 16px;
  }
  &:hover,
  &:focus-visible {
    outline: none;
  }
  &::-ms-expand {
    display: none;
  }
}

.buildVersion {
  color: #fff;
}

@media screen and (max-width: 420px) {
  .langselect {
    font-size: 12px;
    padding: 0;
  }
  .lang-container {
    margin-right: 0 !important;
  }
}
