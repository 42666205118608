/** @format */

.mobility-widget {
  background: #161616;
  color: white;
  height: 100%;
  padding: 2.4rem;
  border-radius: 1.6rem;
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: 3.2rem;
  overflow: hidden;
  transition: 0.3s ease all;
  &--title {
    h2 {
      font-size: 3rem;
    }
  }
  &--data {
    position: relative;
    p {
      font-size: 4rem;
      font-weight: 300;
      margin-bottom: 13px;
    }
    span {
      display: block;
      height: 4px;
      z-index: 2;
      position: relative;
      background-color: #fea500;
      border-radius: 4px;
    }
    &:after {
      content: '';
      height: 4px;
      width: 100%;
      display: block;
      background-color: #6e6e6e;
      position: absolute;
      bottom: 0;
      left: 0;
      border-radius: 4px;
    }
  }
  &--text {
    margin-top: -10px;
    font-size: 1.3rem;
  }
  &--state-text {
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 1.4;
  }
  &--link {
    margin-top: auto;
    font-weight: bold;
    background-image: url($arrow-right-white);
    background-repeat: no-repeat;
    background-position: left -4px;
    padding-left: 3rem;
    a,
    a:hover {
      color: white !important;
    }
  }
}
