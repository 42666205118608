/** @format */

// Light 300
// Regular 400
// Bold 700
// Bold 900

// @import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

@font-face {
  font-family: 'Etelka';
  src: url(../../assets/font/Etelka-Text-Pro_16187.eot), url(../../assets/font/Etelka-Text-Pro_16187.otf),
    url(../../assets/font/Etelka-Text-Pro_16187.svg), url(../../assets/font/Etelka-Text-Pro_16187.ttf),
    url(../../assets/font/Etelka-Text-Pro_16187.woff), url(../../assets/font/Etelka-Text-Pro_16187.woff2);

  font-weight: normal;
}

@font-face {
  font-family: 'Noto Sans';
  src: url(../../assets/font/NotoSans/NotoSans-Regular.ttf);
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans';
  src: url(../../assets/font/NotoSans/NotoSans-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: 'Noto Sans';
  src: url(../../assets/font/NotoSans/NotoSans-Italic.ttf);
  font-style: italic;
}
@font-face {
  font-family: 'Noto Sans';
  src: url(../../assets/font/NotoSans/NotoSans-BoldItalic.ttf);
  font-weight: 700;
  font-style: italic;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Noto Sans', sans-serif;
  font-size: 1.4rem;
}

p {
  font-family: 'Noto Sans', sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  @include media(sx) {
    font-size: 1.6rem;
  }
  @include media(lg) {
    font-size: 1.8rem;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

// for only dashboard page
.welcome-title {
  // font-weight: 400;
  margin-top: 6.4rem;
  // color: $dark-font;
  // font-size: 2.4rem;
  font: 500 30px/36px ContinentalStagSansW-M;
  font-family: 'ContinentalStagSansW-M', 'Noto Sans';
  @include media(xl) {
    margin-top: 3rem;
  }
}

.uppercase {
  text-transform: uppercase;
}
