/** @format */

.magazine-widget {
  height: 100%;
  background-color: $bgWidget;
  padding: 2.4rem;
  border-radius: 1.6rem;
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: 3.2rem;
  overflow: hidden;
  transition: 0.3s ease all;
  &--title {
    h2 {
      font-size: 3rem;
    }
  }
  &--link {
    margin-top: auto;
    font-weight: bold;
    background-image: url($arrow-right);
    background-repeat: no-repeat;
    background-position: left -4px;
    padding-left: 3rem;
  }
  &--list-wrap {
  }
  &--list {
    font-size: 1.8rem;
    display: flex;
    gap: 2.4rem;
  }
  &--list-item {
    flex: 1;
  }
  &--list-item-title {
    margin-top: 1.4rem;
    font-weight: bold;
    @include textrow(1);
  }
  &--list-item-text {
    margin-top: 1.4rem;
    font-weight: 300;
  }
  &--list-item-info {
    font-size: 1.3rem;
    display: flex;
    gap: 0.8rem;
  }
  &--list-item-image img {
    width: 100%;
    display: block;
    object-fit: cover;
    border-radius: 0.8rem;
    transition: 0.3s ease all;
  }
  &--list-item-time {
    &:after {
      content: '•';
      display: inline-block;
      margin-left: 0.8rem;
    }
  }
  &--list-item-date {
    &:after {
      content: '•';
      display: inline-block;
      margin-left: 0.8rem;
    }
  }
  &--list-item-category {
  }
  &--list-empty {
    font-weight: bold;
  }

  &[data-height='2'] {
    gap: 1.2rem;
    .magazine-widget--list-item-image {
      display: none;
    }
    .magazine-widget--list-item-text {
      display: none;
    }
  }
  &[data-height='3'] {
    .magazine-widget--list-item-image img {
      height: 70px;
    }
    .magazine-widget--list-item-text {
      display: none;
    }
  }
  &[data-height='4'] {
    .magazine-widget--list-item-image img {
      height: 110px;
    }
    .magazine-widget--list-item-text {
      @include textrow(2);
    }
  }
  &[data-height='5'] {
    .magazine-widget--list-item-image img {
      height: 170px;
    }
    .magazine-widget--list-item-text {
      @include textrow(4);
    }
  }
  &[data-height='6'] {
    .magazine-widget--list-item-image img {
      height: 270px;
    }
    .magazine-widget--list-item-text {
      @include textrow(4);
    }
  }
  &[data-width='3'] {
    .magazine-widget--list-item:nth-child(n + 2) {
      display: none;
    }
  }
  &[data-width='4'],
  &[data-width='5'] {
    .magazine-widget--list-item:nth-child(n + 3) {
      display: none;
    }
  }
  &[data-width='6'],
  &[data-width='7'] {
    .magazine-widget--list-item:nth-child(n + 4) {
      display: none;
    }
  }
  &[data-width='8'],
  &[data-width='9'] {
    .magazine-widget--list-item:nth-child(n + 5) {
      display: none;
    }
  }
}
