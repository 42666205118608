/** @format */

// @import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;900&display=swap');

.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}

.ant-modal-mask-hidden {
  display: none;
}

.ant-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}

.ant-modal-wrap {
  z-index: 1051;
}

.ant-modal-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 900;
  font-size: 3.6rem;
  padding: 15px 0 0 0;
}

.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 10px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 300;
  font-size: 22px;
  line-height: 1.32;
  letter-spacing: normal;
}

.ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  display: none;
}

.ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}

.ant-modal-close:focus,
.ant-modal-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}

.ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-radius: 10px 10px 0 0;
}

.ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;

  p {
    font-size: 2rem;
    line-height: 1.32;
    color: #333;
    font-weight: 300;
    margin-bottom: 1rem;
  }
}

.ant-modal-footer {
  padding: 20px 30px;
  text-align: right;
  background: transparent;
  border-radius: 0 0 5px 5px;
  border-top: 1px solid #a3a5a6;
  margin: 0 20px;
}

.ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  margin-bottom: 0;
  margin-left: 8px;
}

.ant-modal-open {
  overflow: hidden;
}

.ant-modal-centered {
  text-align: center;
}

.ant-modal-centered::before {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: '';
}

.ant-modal-centered .ant-modal {
  top: 0;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .ant-modal {
    max-width: calc(100vw - 16px);
    margin: 8px auto;
  }

  .ant-modal-centered .ant-modal {
    flex: 1 1;
  }
}

.ant-modal-confirm .ant-modal-header {
  display: none;
}

.ant-modal-confirm .ant-modal-body {
  padding: 32px 32px 24px;
}

.ant-modal-confirm-body-wrapper::before {
  display: table;
  content: '';
}

.ant-modal-confirm-body-wrapper::after {
  display: table;
  clear: both;
  content: '';
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}

.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}

.ant-modal-confirm-body > .anticon {
  float: left;
  margin-right: 16px;
  font-size: 22px;
}

.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 38px;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  float: right;
  margin-top: 24px;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-bottom: 0;
  margin-left: 8px;
}

.ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
  color: #ff4d4f;
}

.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #faad14;
}

.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color: #1890ff;
}

.ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
  color: #52c41a;
}

.ant-modal-wrap-rtl {
  direction: rtl;
}

.ant-modal-wrap-rtl .ant-modal-close {
  right: auto;
  right: initial;
  left: 0;
}

.ant-modal-wrap-rtl .ant-modal-footer {
  text-align: left;
}

.ant-modal-wrap-rtl .ant-modal-footer .ant-btn + .ant-btn {
  margin-right: 8px;
  margin-left: 0;
}

.ant-modal-wrap-rtl .ant-modal-confirm-body {
  direction: rtl;
}

.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}

.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-right: 38px;
  margin-left: 0;
}

.ant-modal-wrap-rtl .ant-modal-confirm-btns {
  float: left;
}

.ant-modal-wrap-rtl .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-right: 8px;
  margin-left: 0;
}

.ant-modal-wrap-rtl.ant-modal-centered .ant-modal {
  text-align: right;
}

.modal-footer .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mr-auto {
  margin-right: auto !important;
}

.modal-footer .link-cta a {
  border-bottom: none;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  color: #234d96;
}

.cookie-modal-buttons .btn {
  border-radius: 0;
  margin-left: 20px;
  text-decoration: none;
  text-transform: uppercase;
  display: inline-block;
  border: none;
  padding: 13px 35px;
  font-size: 22px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  cursor: pointer;
}

.cookie-modal-buttons .btn-secondary {
  box-shadow: none;
  background-color: transparent;
  color: #333;
  position: relative;
  margin-right: 45px;

  &:before {
    position: absolute;
    left: 8px;
    top: 15px;
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-top: 14px solid transparent;
    border-left: 14px solid #234d96;
    border-bottom: 14px solid transparent;
  }
}

.cookie-modal-buttons .btn-primary {
  box-shadow: none;
  background-color: transparent;
  color: #333;
  position: relative;
  padding: 16px 49px 16px 76px;
  border: 1px solid #000;
  border-radius: 50px;

  &:hover {
    color: #fff;
    background-color: #08c476;
    border-color: #08c476;
  }

  .cookie-modal-buttons .btn-primary:hover::before {
    border-left: 14px solid #ffffff;
  }

  &:before {
    position: absolute;
    left: 50px;
    top: 15px;
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-top: 14px solid transparent;
    border-left: 14px solid #09c476;
    border-bottom: 14px solid transparent;
  }
  &:hover {
    background-color: #09c476;
    color: #fff;
    &::before {
      border-top: 14px solid transparent;
      border-left: 14px solid #ffff;
      border-bottom: 14px solid transparent;
    }
  }
}

.form-check {
  display: flex;
  align-items: center;
}

.inputs {
  display: flex;
  width: 260px;
  justify-content: space-between;
  @media only screen and (max-width: 450px) {
    width: 200px;
  }
}

.modal-footer {
  hr {
    display: none;
  }
}

// .cookie-toggle {
//   display: flex;
// }

.cookie-modal-info {
  margin-left: auto;
}

#required-cookies > tbody td {
  border-bottom: 3px solid #e4e7eb;
  padding: 15px;
}

.purpose-header {
  width: 50%;
}

.accordion-table-container section {
  margin: 10px 0;
}

#statistic-cookies {
  @extend #required-cookies;
  width: 100%;
}

.accordion-modal-title {
  cursor: pointer;
}

/*Media Quereis for Modal */
@media only screen and (max-width: 768px) {
  .ant-modal-title {
    font-size: 2rem;
  }
  .ant-modal-body {
    padding: 0 20px;

    p {
      font-size: 1.4rem;
      line-height: 1.32;
      color: #333;
      font-weight: 300;
      margin-bottom: 1rem;
    }
  }
  .form-check {
    margin-right: 10px;
  }
  .cookie-modal-buttons {
    .btn-primary {
      padding: 10px;

      &:before {
        position: absolute;
        transition: all 1s ease-in;
        left: 15px;
        top: 18px;
        content: '';
        border-left: 10px solid #09c476;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
      }
    }

    .btn {
      font-size: 12px;
      padding: 20px 30px;
      margin-left: 0;
    }

    .btn-secondary {
      margin-right: 0;
      padding: 20px !important;

      &:before {
        border-top: 10px solid transparent;
        border-left: 10px solid #234d96;
        border-bottom: 10px solid transparent;
        top: 18px;
        left: 5px;
      }
    }
  }
}

@media only screen and (max-width: 1440px) {
  .ant-modal.ant-zoom-appear.ant-zoom-appear-active.ant-zoom {
    width: auto !important;
    padding: 50px;
  }
}
@media only screen and (max-width: 450px) {
  .ant-modal.ant-zoom-appear.ant-zoom-appear-active.ant-zoom {
    padding: 15px;
  }
  .ant-modal.ant-zoom-appear.ant-zoom-appear-active.ant-zoom {
    padding: 15px;
  }
  .modal-footer {
    .row {
      flex-direction: column;
    }
  }
  #required-cookies > tbody td {
    padding: 5px;
  }
}

.cookie-toggle {
  justify-content: space-between;
  display: flex;
  margin-top: 27px;
  align-items: baseline;
  .inputs {
    display: flex;
    .checkbox-container {
      display: block;
      position: relative;
      margin-bottom: 12px;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      // input[type=checkbox] {
      //   position: absolute;
      //   opacity: 0;
      //   cursor: pointer;
      //   height: 0;
      //   width: 0;
      // }
      label {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #b2b2b2;
        @media only screen and (max-width: 450px) {
          font-size: 13px;
        }
      }
      input:disabled:checked + label:after {
        border-bottom-color: #b2b2b2;
        border-left-color: #b2b2b2;
      }
      input:checked + label:after {
        transform: rotate(-51deg) skew(-17deg, 0deg);
        content: '';
        position: absolute;
        top: 8px;
        left: -18px;
        display: block;
        width: 8px;
        height: 4px;
        border-bottom: 1px solid #fff;
        border-left: 1px solid #fff;
      }
      input:checked + label:after {
        content: '';
        font-family: Glyphicons Halflings;
        font-size: 10px;
        top: -2px;
        left: -20px;
      }
      input:disabled + label:after {
        color: #0c1c47;
      }
      input:checked + label:after {
        color: #fff;
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  #statistic-cookies,
  #required-cookies {
    font-size: 12px;
  }
}
