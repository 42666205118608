/** @format */

// color
$white: #fff;
$black: #000;
$blue: rgba(35, 77, 150, 1);
/* $body-bg: rgba(214, 214, 214, 0.2); // background color */
$body-bg: #e6e0d8;
$gray: #d6d6d6;
$gray-dark: #868686;
$dark-font: rgba(51, 51, 51, 1);
$green: rgba(8, 196, 118, 1);
$dark-green: #08a262;
$dropdown-bg: rgba(226, 226, 226, 1);
$footer-link: rgba(214, 214, 214, 1);
$warning: rgba(242, 165, 14, 1);

$primary-orange: #fea500;
$color-light-gray: #cdcdcd;
$color-light-green: #2db928;
$color-light-blue: #00a5dc;
$color-red: #ff2d37;

$bgWidget: #fffdfa;

//icon
$arrow: '../../assets/icon/arrow.svg';
$arrow-right: '../../assets/icon/arrow-right.svg';
$arrow-right-white: '../../assets/icon/arrow-right-white.svg';
$arrow-triangle: '../../assets/icon/arrow-triangle.svg';
$arrow-triangle-blue: '../../assets/icon/arrow-triangle-blue.svg';
$green-triangle: '../../assets/icon/green-triangle.svg';
$green-triangle-back: '../../assets/icon/green-triangle-back.svg';
$black-triangle-back: '../../assets/icon/black-triangle-back.svg';
$white-triangle-back: '../../assets/icon/white-triangle-back.svg';
$gray-triangle-back: '../../assets/icon/gray-triangle-back.svg';
$white-triangle: '../../assets/icon/white-triangle.svg';
$black-triangle: '../../assets/icon/black-triangle.svg';
$back-arrow: '../../assets/icon/back-arrow.svg';
$back-arrow-white: '../../assets/icon/back-arrow-white.svg';
$back-arrow-gray: '../../assets/icon/back-arrow-gray.svg';
$gray-triangle: '../../assets/icon/gray-triangle.svg';
$white-right-arrow: '../../assets/icon/white-right-arrow.svg';
$black-right-arrow: '../../assets/icon/black-right-arrow.svg';
$green-right-arrow: '../../assets/icon/green-right-arrow.svg';
$gray-right-arrow: '../../assets/icon/gray-right-arrow.svg';
$green-check: '../../assets/icon/green-check.svg';
$black-check: '../../assets/icon/black-check.svg';
$white-check: '../../assets/icon/white-check.svg';
$gray-check: '../../assets/icon/gray-check.svg';
$oval: '../../assets/icon/oval.svg';
$search: '../../assets/icon/search.svg';
$search-blue: '../../assets/icon/search-blue.svg';
$play: '../../assets/icon/play.svg';
$location-icon: '../../assets/icon/location-icon.svg';
// $play-arrow : '../../assets/icon/play-arrow.svg';

//header size
// $header-height: 8.6rem;
$header-height: 13.5rem;
