/** @format */

.breadcrumbWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 40px auto;
  @media screen and (max-width: 991px) {
    margin: 25px 0;
  }
  .homeIcon {
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-right: 8px;
  }

  .breadcrumbItemSeparator {
    color: $black;
    margin-left: 8px;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .breadcrumbItemText {
    color: $blue;
    margin-right: 8px;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .breadcrumbItemDropdownBtn {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 8px;
  }

  .breadcrumbArrowDown {
    color: $blue;
  }
}
