/** @format */

.link-widget {
  height: 100%;
  background-color: $bgWidget;
  padding: 2.4rem;
  border-radius: 1.6rem;
  overflow: hidden;
  &--list-item {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 1.6rem;
    height: 100%;
  }
  &--list-item-image {
    position: relative;
    img {
      border-radius: 0.8rem;
      object-fit: cover;
      width: 100%;
      transition: 0.3s ease all;
    }
  }
  &--list-item-category {
    position: absolute;
    top: 0.8rem;
    left: 0.8rem;
    background-color: #fea500;
    border-radius: 0.5rem;
    padding: 4px 8px;
    color: black;
    font-size: 1.3rem;
  }
  &--list-item-title {
    font-family: 'ContinentalStagSansW-M', 'Noto Sans';
    font-size: 1.8rem;
  }
  &--list-item-text {
    font-weight: 300;
    font-size: 1.8rem;
    @include textrow(3);
  }
  &--list-item-link {
    font-size: 1.6rem;
    font-weight: bold;
    background-image: url($arrow-right);
    background-repeat: no-repeat;
    background-position: left -4px;
    padding-left: 3rem;
    margin-top: auto;
  }

  &[data-height='3'] {
    .link-widget--list-item-image img {
      height: 90px;
    }
  }
  &[data-height='4'] {
    .link-widget--list-item-image img {
      height: 200px;
    }
  }
  &[data-height='5'] {
    .link-widget--list-item-text {
      @include textrow(4);
    }
    .link-widget--list-item-image img {
      height: 280px;
    }
  }
}
